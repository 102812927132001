import React, { Component } from 'react';
import { FaAngleUp, FaAngleDown, FaRegBookmark, FaShare, FaCube, FaChartLine } from 'react-icons/fa';
import { connect } from 'react-redux'

import recommended from 'assets/case_view_icons/recommended.svg'
import toBeDiscussed from 'assets/case_view_icons/toBeDiscussed.svg'
import notRecommended from 'assets/case_view_icons/notRecommended.svg'

import share from 'assets/icons/iconoCompartir.svg'
import icono3dGrisCruz from 'assets/icons/icono3dGrisCruz.svg'
import icono3dGrisTick from 'assets/icons/icono3dGrisTick.svg'
import iconoScoreGrisCruz from 'assets/icons/iconoScoreGrisCruz.svg'
import iconoScoreGrisTick from 'assets/icons/iconoScoreGrisTick.svg'

import iconoFlechaArribaAzul from 'assets/icons/iconoFlechaArribaAzul.svg'
import iconoFlechaAbajoAzul from 'assets/icons/iconoFlechaAbajoAzul.svg'

import {ShareBottomView} from 'components/common';

import {threeDModelCases} from './threeDModelCases.js'

import {getShareUrlCaseStudy} from 'utils/generalFunctions'

class CaseInfoBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detailsOpen: false,
            shareOpen: false,
        }
    }

    render() {
        let caseStudy = this.props.caseStudy
        const circleInfo = (title, backgroundColor, color) => {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="circle-info" style={{ backgroundColor: backgroundColor }}>
                        <span style={{ fontSize: 12, color: color, fontWeight: "400" }}>{title}</span>
                    </div>
                    {/* <div style={{ width: 5 }}></div> */}
                </div>
            )
        }
        const changeDetailsSectionState = () => {
            this.setState({
                detailsOpen: !this.state.detailsOpen
            })
            this.props.notify()
        }
        const iconSize = 28
        const shareLink = () => {
            
            this.setState((state) => ({shareOpen: !state.shareOpen}))
            /*if (navigator.share) {
                navigator.share({
                    title: 'Web Fundamentals',
                    text: 'Check out Web Fundamentals — it rocks!',
                    url: 'https://developers.google.com/web',
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
            }else{
                console.log("no")
            }*/
        }
        const middleActions = [
            // { icon: <img src={caseStudy.model_url != null? icono3dGrisTick : icono3dGrisCruz} width={iconSize} height={iconSize} />, click: () => { } },
            { icon: <img src={caseStudy.web_model_url? icono3dGrisTick : icono3dGrisCruz} width={iconSize} height={iconSize} />, click: () => { } },
            { icon: <img src={caseStudy.include_score_info ? iconoScoreGrisTick : iconoScoreGrisCruz} width={iconSize} height={iconSize} />, click: () => { } }
        ]

        const overallAssessmentIcon = {
            "Recommended": recommended,
            "To be discussed": toBeDiscussed,
            "Not Recommended": notRecommended,
        }

      
        return (
            <div className="case-actions-bar" style={this.props.individualView ? {borderTop: "1px solid rgb(176,176,176)"} : {} }>
                <div className="case-actions-bar-left" style={this.props.individualView ? {width:  "calc(100% - 144px)", padding: "0px 0px"} : {width: "calc(100% - 192px)"}}> 
                    <span style={{paddingRight: "20px"}}>
                        {caseStudy.age + " years"}
                    </span>
                    <span >
                        {" " + caseStudy.accident_time}
                    </span>
                    {/* <div>
                        <img src={overallAssessmentIcon[caseStudy.overall_assessment]} width={iconSize} height={iconSize} />
                    </div> */}

                </div>
                <div className="case-actions-bar-middle"  style={{width: this.props.individualView ? "96px" : "96px"}}>
                    {middleActions.map((action) => {
                        return (
                            <div className="case-actions-bar-middle-container">
                                <div onClick={action.click}>
                                    {action.icon}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="case-actions-bar-right" style={{width: this.props.individualView ? "48px" : "96px"}}>
                    <div className="action-share-container" style={this.props.individualView ? {} : {borderRight: "1px solid rgb(176,176,176)"}}>
                        <div onClick={shareLink}>
                            <img src={share} width={iconSize} height={iconSize} />
                        </div>
                    </div>
                    {!this.props.individualView &&
                        <div style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "50%"}}>
                            {this.state.detailsOpen ? 
                            <img src={iconoFlechaArribaAzul} width={iconSize} height={iconSize} onClick={changeDetailsSectionState} />
                            : <img src={iconoFlechaAbajoAzul} width={iconSize} height={iconSize}  onClick={changeDetailsSectionState} />}
                        </div>
                    }

                </div>
                <ShareBottomView display={this.state.shareOpen} shareUrl={getShareUrlCaseStudy(caseStudy.id)} closeFunction={shareLink} />
            </div>
        )
    }

}

const mapStateToProps = state => ({
    caseStudySelected: state.regions.caseStudy,
    error: state.error
});

export default connect(
    mapStateToProps,
    {}
)(CaseInfoBar);