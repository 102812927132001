import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import './case-view.css';
import LazyLoad from 'react-lazy-load';
import CaseInfoBar from './caseInfoBar'
import CaseDetails from './caseDetails'

class CaseView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            detailsOpen: false,
        }
    }
    showActionBar = () => {
        return (
            <CaseInfoBar caseStudy={this.props.caseStudy}  individualView={false} 
                notify={() => { this.setState({ detailsOpen: !this.state.detailsOpen }) }} />
        )
    }

    showDetails = () => {
        if (!this.state.detailsOpen) return null
        return (
         
            <div style={{position: "relative"}}>
                <div className="caseDetailsView" >
                    <CaseDetails caseStudy={this.props.caseStudy} />
                </div>
            </div>
        )
    }
    render() {
        const caseStudy = this.props.caseStudy
        return (
            <div className="case-view-container">
                {
                    this.props.spaceAtTop &&
                    <div className="case-view-container-separator">
                    </div>
                }
                <LazyLoad
                    width="100%"
                    height="auto"
                    debounce={false}
                    offsetVertical={500}>
                    <img src={caseStudy.cover_image_url} alt="case-view" className="case-image"
                        onClick={() => { this.props.history.push('/case-study/' + caseStudy.id) }}  style={{cursor:"pointer"}} />
                </LazyLoad>

                <div style={{  borderBottom: "1px solid rgb(140,140,140)"}}>
                    {this.showActionBar()}
                    {this.showDetails()}
                </div>
            </div >
        )
    }

}

export default withRouter(CaseView);