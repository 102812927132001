import React from 'react';
import './region.scss';

import {cleanNumberFromFracturalTypeName} from 'utils/generalFunctions'

const RegionCard = ({ title, urlImage, onClick, selected }) => {
    let cardStyle = selected ? "region-card selected" : "region-card" 
    let containerStyle = selected ? "region-card-container selected" : "region-card-container" 


    return (
        <div className={cardStyle}>
            <div className={containerStyle} onClick={onClick}>
                <img src={urlImage} class="region-card-image" alt="region-stories" />
            </div>
            <div className="region-card-title-container">
                <p className="region-card-title">{cleanNumberFromFracturalTypeName(title)}</p>
            </div>
        </div>
    )
}

export default RegionCard;
