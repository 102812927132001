import React, { Component } from 'react'
import { logout } from 'actions/authActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {
    FaSearch
} from 'react-icons/fa'

class NavBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    loginDiv = () => {
        return (
            <div style={{ display: "flex", float: "right" }} >
                <div style={{ marginRight: "10px" }}>
                    Martin Michelini
                </div>
                <div style={{ marginRight: "10px" }}>
                    <button onClick={() => { this.logoutPage() }}>LOGOUT</button>
                </div>
            </div>
        )
    }
    logoutPage = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userJson');
        this.props.logout()
        this.props.history.push('')
        window.location.reload()
    }
    render() {


        const { isAuthenticated } = this.props.auth;

        let loginDiv = isAuthenticated ? this.loginDiv() : '';

        return (
            <div style={{ height: '10vh', backgroundColor: '#186871', display: 'flex' }}>
                <div style={{ margin: 10, backgroundColor: 'black', width: '30%', height: 40 }}>
                    <div style={{ margin: 5, backgroundColor: 'white', height: 30, display: 'flex' }}>
                        <FaSearch size={24} color="#4c8b90" />
                        <input style={{ marginLeft: 10, width: "90%", border: '1px solid #4c8b90' }}></input>
                    </div>
                </div>
                <div style={{ margin: 10, backgroundColor: 'white', width: '70%', height: 40 }}>
                    {loginDiv}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logout }
)(withRouter(NavBar));