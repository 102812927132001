import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as constants from '../../../utils/constants'
import './navBar.css'
import { FaLongArrowAltLeft } from 'react-icons/fa';
import BackgroundImage from "../../../assets/backgroundRegions.png";

import {papersCategories} from 'utils/constants'

class PapersMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapse: false,
        }
    }
    menuActions = () => {
        
        const option = (paperCategory) => {
            return (
                <div key={paperCategory.id} className="menuOption" style={{ marginLeft: this.props.marginLeftPaperCategory + "px", cursor: "pointer" }}
                    onClick={() => { this.props.navigateToPapers(paperCategory.id, paperCategory.name) }}>
                    <p className="menuOptionOtherText">{paperCategory.name}</p>
                </div>
            )
        }

        return (
            <div>
                <div className="menu-option-subÏ-container" style={{marginTop: "10px", marginLeft: this.props.menuOptionSubContainerLeftMargin + "px"}}>
                    {papersCategories.map((paperCategory) => {
                        if(process.env.REACT_APP_IS_STAGING === "true" && paperCategory.name === "ICUC" )
                            return;

                        return option(paperCategory)
                    })}
                </div>
            </div>
        )
    }
    render() {
        return (
            <div>
                {this.menuActions()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(PapersMenu));
