import React from 'react'
import './input.css'

function InputDate(props) 
{
    let {name, onChange, min, max, placeholder } = props

    return (
        <div className="customInputContainer">
            <div className="customInputInnerContainer" style={{position: "relative"}}>
                <input className="customInputStyle"
                    name={name}
                    type="date"
                    onChange={onChange}
                    min={min}
                    max={max}
                    placeholder={placeholder}
                />
                <div style={{height: "50%", width:"calc(10% - 1px)", backgroundColor: "rgb(227, 227, 227)", position: "absolute", top: "5%", left: "90%", pointerEvents: "none"}}>

                </div>
            </div>
        </div>
    )
}

export default InputDate