import {
    GET_SEARCH_STUDY_CASES
} from '../actions/types';

const initialState = {
    searchStudyCases: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SEARCH_STUDY_CASES:
            return {
                ...state,
                searchStudyCases: action.searchStudyCases
            };
        default:
            return state;
    }
}  