import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import regionsReducer from './regionsReducer'
import searchReducer from './searchReducer'
import papersReducer from './papersReducer'
import menuReducer from './menuReducer'

export default combineReducers({
    auth: authReducer,
    error: errorReducer,
    regions: regionsReducer,
    search: searchReducer,
    papers: papersReducer,
    menu: menuReducer,
})