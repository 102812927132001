import React from 'react'
import './input.css'

const Input = ({ name, placeholder, type, value, readonly, onChange = () => { }, error = false, border }) => {
    let borderStyle = 'none'
    if(border) borderStyle = '1px solid black'
    if(border && (value==='' || error)) borderStyle = '1px solid black'
    const styles = { border: borderStyle } 
    return (
        <div className="customInputContainer"
            style={styles}>
            <div className="customInputInnerContainer">
                <input className="customInputStyle"
                    onFocus={(e) => e.target.placeholder = ""}
                    onBlur={(e) => e.target.placeholder = placeholder}
                    autocomplete="off"
                    name={name}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    onChange={onChange}
                    error={error}
                    readOnly={readonly}
                />
            </div>
        </div>
    )
}

export default Input