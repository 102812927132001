import React, { Component } from 'react'
import { NavLink, /*Route*/ } from 'react-router-dom'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import logo from '../../assets/blueLogo.png'
import {
    FaUser, FaArrowAltCircleRight, FaArrowAltCircleLeft, FaComment, FaInfoCircle
} from 'react-icons/fa'

class SideBarMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapse: true,
            selected: '',
        }
    }
    separator = () => {
        return (
            <div style={{ witdh: 10, height: 1, backgroundColor: 'white' }}>
            </div>
        )
    }
    render() {
        const iconSize = 20, iconColor = "#9abcc0"
        const links = [
            { name: "LOGIN", icon: <FaUser size={iconSize} color={iconColor} />, link: "/login" },

            { name: "REGISTER", icon: <FaUser size={iconSize} color={iconColor} />, link: "/register" },
            { name: "ACCOUNT", icon: <FaUser size={iconSize} color={iconColor} />, link: "/profile" },

            { name: "ABOUT", icon: <FaComment size={iconSize} color={iconColor} />, link: "/about" },
            { name: "TERMS", icon: <FaComment size={iconSize} color={iconColor} />, link: "/terms" },
            { name: "FEEDBACK", icon: <FaComment size={iconSize} color={iconColor} />, link: "/feedback" },

            { name: "ICUC Score", icon: <FaInfoCircle size={iconSize} color={iconColor} />, link: "/sections" },
            { name: "ICUC Papers", icon: <FaInfoCircle size={iconSize} color={iconColor} />, link: "/sections" },
            { name: "ICUC Insights", icon: <FaInfoCircle size={iconSize} color={iconColor} />, link: "/sections" },
        ]
        const iconsCollapse = [
            <FaArrowAltCircleRight size={iconSize} color={iconColor} />,
            <FaArrowAltCircleLeft size={iconSize} color={iconColor} />,
        ]
        const menuLogo = () => {
            const titleStyle = { fontSize: 32, marginTop: 40, marginLeft: 20, color: '#337b81' }
            return (
                <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh',
                }}>
                    <Link to="/">
                        <img alt="" src={logo} style={{ height: 80, marginTop: 20, }} />
                    </Link>
                    {this.state.collapse ? null :
                        <p style={titleStyle}>
                            ICUC
                        </p>
                    }
                </div>
            )
        }
        const menuActions = () => {
            const textStyle = { fontSize: 14, marginLeft: 20, marginTop: 10, color: '#438577', fontWeight: 'bold' }
            return (
                <div style={{ marginTop: 30 }}>
                    {links.map((link) => {
                        return (
                            <div style={{ marginLeft: 25, display: 'flex', height: 42 }}>
                                <div className={link.name === this.state.selected ? 'selectedMenuIcon' : 'menuIcon'}
                                    onClick={() => { this.setState({ selected: link.name }) }} >
                                    <NavLink to={link.link}>
                                        {link.icon}
                                    </NavLink>
                                </div>
                                {this.state.collapse ? null :
                                    <div style={textStyle} onClick={() => { this.setState({ selected: link.name }) }} >
                                        <NavLink to={link.link} style={{ color: '#438577', }}>
                                            {link.name}
                                        </NavLink>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            )
        }
        const collapseActions = () => {
            const collapseMenu = () => { this.setState({ collapse: !this.state.collapse, }) }
            return (
                <div style={{ height: '20vh' }}>
                    <div className="menuIcon" style={{ marginTop: 15, marginLeft: 25, }} onClick={collapseMenu}>
                        {this.state.collapse ? iconsCollapse[0] : iconsCollapse[1]}
                    </div>
                </div>
            )
        }
        const menuLogoMobile = () => {
            return (
                <div className="mobileMenuPositions">
                    <Link to="/">
                        <img alt="" src={logo} style={{ height: 60, paddingLeft: 15, marginTop: 10}} />
                    </Link>
                    <div className="hamburgerMenu">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div className="navBarWeb" style={{ width: this.state.collapse ? 100 : 250 }}>
                    {menuLogo()}
                    {menuActions()}
                    {collapseActions()}
                </div>
                <div className="navBarMobile" >
                    {menuLogoMobile()}
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    //auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(SideBarMenu));