import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as constants from '../../../utils/constants'
import './navBar.css'
import { FaLongArrowAltLeft } from 'react-icons/fa';
import BackgroundImage from "../../../assets/backgroundRegions.png";

class RegionsMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapse: false,
        }
    }
    menuActions = () => {
        const upperLimbRegions = [
            {
                name: "Proximal Humerus",
                id: constants.subRegions.ProximalHumerus,
                available: true,
            },
            {
                name: "Clavicle/Scapula",
                id: constants.subRegions.Clavicle,
                available: true,
            },
            {
                name: "Humeral Shaft",
                id: constants.subRegions.HumeralShaft,
                available: true,
            },
            {
                name: "Distal Humerus",
                id: constants.subRegions.DistalHumerus,
                available: true,
            },
            {
                name: "Proximal Forearm",
                id: constants.subRegions.ProximalForearm,
                available: true,
            },
            {
                name: "Forearm Shaft",
                id: constants.subRegions.ForearmShaft,
                available: true,
            },
            {
                name: "Distal Radius",
                id: constants.subRegions.DistalRadius,
                available: true,
            },
            {
                name: "Hand",
                id: constants.subRegions.Hand,
                available: false,
            }
        ]
        const lowerLimbRegions = [
            {
                name: "Proximal Femur",
                id: constants.subRegions.ProximalFemur,
                available: true,
            },
            {
                name: "Femoral Shaft",
                id: constants.subRegions.FemoralShaft,
                available: true,
            },
            {
                name: "Distal Femur",
                id: constants.subRegions.DistalFemur,
                available: true,
            },
            {
                name: "Patella",
                id: constants.subRegions.Patella,
                available: true,
            },
            {
                name: "Proximal Tibia",
                id: constants.subRegions.ProximalTibia,
                available: true,
            },
            {
                name: "Tibial Shaft",
                id: constants.subRegions.TibialShaft,
                available: true,
            },
            {
                name: "Ankle",
                id: constants.subRegions.Ankle,
                available: true,
            },
            {
                name: "Foot",
                id: constants.subRegions.Foot,
                available: false,
            }
        ]
        const title = (name) => {
            return (
                <div className="menuOption">
                    <p className="menuOptionText">{name}</p>
                </div>
            )
        }
        const navigateToRegionOrInsight = (region) => {
            if (region.available)
                this.props.navigateToRegion(region.id)
        }
        const option = (region, marginLeft) => {
            return (
                <div key={region.name} className="menuOption" style={{ marginLeft: marginLeft + "px", cursor: "pointer" }}
                    onClick={() => { navigateToRegionOrInsight(region) }}>
                    <p className={region.available ? "menuOptionOtherText" : "menuOptionOtherTextDisable"}>{region.name}</p>
                </div>
            )
        }

        const titleMenu = (name, marginLeft) => {
            return (
                <div style={{ fontWeight: "bold", marginLeft: marginLeft + "px" }}>
                    {/* <span onClick={() => this.props.goBackToFirstMenu()} style={{ cursor: "pointer" }}><FaLongArrowAltLeft size={24} /></span> */}
                    {title(name)}
                </div>
            )
        }

        return (
            <div>
                <div className="menu-option-sub-container" style={{marginTop: "15px", marginLeft: this.props.menuOptionSubContainerLeftMargin + "px"}}>
                    
                    <div class="row"  style={{margin: "0px"}}>
                        <div class={this.props.desktopVersion? "col-6" : "col-5"} style={{padding: "0px"}}>
                            {titleMenu("UPPER LIMB", this.props.marginLeftUpperLimb)}
                            {upperLimbRegions.map((region) => {
                                return option(region, this.props.marginLeftUpperLimb)
                            })}                    
                        </div>
                        <div class={this.props.desktopVersion? "col-6" : "col-7"} style={{padding: "0px"}}>
                            {titleMenu("LOWER LIMB", this.props.marginLeftLowerLimb)}
                            {lowerLimbRegions.map((region) => {
                                return option(region,this.props.marginLeftLowerLimb)
                            })}                    
                        </div>
                    </div>

                </div>
            </div>
        )
    }
    render() {
        return (
            <div>
                {this.menuActions()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(RegionsMenu));
