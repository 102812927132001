import React, { Component } from 'react';

class Loader extends Component {

	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render() {
		return (
			<div style={{ height: this.props.height ? this.props.height : "auto", fontSize: 18, fontWeight: "600" }}>
				Loading..
			</div>
		);
	}
}

export default Loader;
