import React from 'react'
import './Terms.css'

const PrivacyPolicy = () => {
    const policy = [
        {
            title: 'Privacy Policy',
            description:
                <p>
                    Some trademarks, registered trademarks or service marks, product names, company names and logos are protected by intellectual property rights, even though specific reference to this fact is not always made on this site. <br></br>
                    ICUC GmbH (ICUC) is committed to maintaining the integrity and privacy of your personal information collected through this site and wants you to be familiar with how we collect, use, and disclose information. <br></br>
                    By using this site, you agree with the terms of this Privacy Policy. Whenever you submit information via this site, you consent to the collection, use, and disclosure of that information in accordance with this Privacy Policy.
            </p>,
        },
        {
            title: '1. Information Collection',
            description:
                <p>
                    ICUC may collect personal data voluntarily provided by users of the site (such as name, address, e-mail address, telephone number etc.). Personal data will only be used to improve the content of the Site and the comfort of the user, for marketing purposes. Any and all personal data will only be used by ICUC. Other persons will not be informed on personal data.
                </p>
        },
        {
            title: '2. Data Transfer to Third Parties',
            description:
                <p>
                    By accessing and using ICUC Site, you consent to allow free exchange of your personal data in accordance between ICUC and other companies and institutions. ICUC may share your personal data under confidentiality agreements with other companies that work with, or on behalf of, ICUC to promote products or services. These companies may use your personal data to assist ICUC in its operations. These companies do not have any independent right to share this information.
                </p>
        },
        {
            title: '3. Non-disclosure',
            description:
                <p>
                    Subject to the exceptions set out above, any personal data you disclose to ICUC will not be disclosed to third parties.
                </p>
        },
        {
            title: '4. Data Security',
            description:
                <p>
                    This Site may contain links to other websites. ICUC is not responsible for and has no control over the content, accuracy or opinions expressed in such websites. ICUC does encourage users of this Site to carefully read the privacy policy of every website they visit.<br></br>
                    By accepting the "Terms of Use" you agree not to link, upload, post or transmit any illegal, obscene, offensive or otherwise inappropriate material to the ICUC Site. In addition, you agree not to use any of ICUC proprietary logos, marks, or other distinctive graphics, video, or audio material in your links, without the express and prior written permission, which ICUC may withhold in its sole discretion. Furthermore, you agree not to link in any manner reasonably likely to suggest affiliation with or endorsement or sponsorship by ICUC. <br></br>
                    You may review and request changes to your personal data that ICUC has collected, including the removal of your personal data from ICUCs databases in order to prevent receipt of future communications or to halt receipt of our site services by sending your request via e-mail to support@icuc.net.
                </p>
        },
        {
            title: '5. Non-personal Data',
            description:
                <p>
                    By choosing to send a message to ICUC via our site, you acknowledge that all non-personal information contained in your message, including feedback data, such as questions, comments, ideas, suggestions, or the like (collectively "Feedback") will be considered to be non-confidential. <br></br>
                    Under no circumstances should you use our web site or any of the fax and mailing addresses on the web site to send ICUC confidential or proprietary information. <br></br>
                    Data which is not personal, and ICUC shall be free to disclose and use the same, without any obligations whatsoever towards users of the Site, for any purpose and through any means. <br></br>
                    By accepting these Terms of Use you grant ICUC a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any content (Feedback) that you submitted to the Site. This worldwide license ends when you delete your account or when you notify us that you wish to revoke a certain content and you clearly identify it to us. ICUC will take the steps to delete this content unless you have shared it with others, and they have not deleted it. You acknowledge that deleting your content is not instantaneously and may take a reasonable period of time.
                </p>
        },
        {
            title: '6. Contact',
            description:
                <p>
                    If you have any questions, comments, or concerns about this Privacy Policy, please contact us at: <a href="mailto:support@icuc.net">support@icuc.net</a>
                </p>
        },
    ]
    return (
        <div className="terms-container">
            {policy.map((aPolicy) => {
                return (
                    <div>
                        <p className="terms-title">{aPolicy.title}</p>
                        <p className="terms-description">{aPolicy.description}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default PrivacyPolicy