import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

import { selectMenuLevelOne, selectMenuLevelTwo } from 'actions/menuActions';

const PrivateRoute = ({ component, auth, ...rest }) => {
  let ComponentToRender = component;

  if(!auth.isAuthenticated)
  {
    rest.selectMenuLevelOne(null)
    rest.selectMenuLevelTwo(null, null)
  }

  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated ?  <ComponentToRender {...props} /> : <Redirect to={{
          pathname: '/login',
          state: {redirectLink: rest.location.pathname + ( rest.location.search || "")}
         }} />
      }
    />
  );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default withRouter(connect(mapStateToProps, { selectMenuLevelOne, selectMenuLevelTwo })(PrivateRoute));
