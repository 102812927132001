import React from 'react';
import './cases.css';
import CaseView from './case_view'

const Cases = ({ cases }) => {
    return (
        <div className="cases-grid">
            {cases.map((caseStudy, index) => {
                return (
                    <div key={caseStudy.id}>
                        <CaseView caseStudy={caseStudy} spaceAtTop={index>0} />
                    </div>
                )
            })}
        </div>
    )
}

export default Cases;
