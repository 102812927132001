import {
    GET_CASE_TYPES,
    GET_CASE_STUDY,
    GET_CHAPTER_BUTTON_SETS,
    GET_SUB_REGION_CASES_BY_CASE_TYPE,
    GET_REFERENCE_CASES,
    GET_EXPERT_OPINIONS,
    GET_REFERENCE_CASE_BY_ID,
    GET_EXPERT_OPINION_BY_ID,
    SELECT_REGION,
    GET_3D_CASE_STUDY,
    SET_SELECTED_CASE_TYPE,
    SET_SELECTED_INSIGHT,
} from '../actions/types';

const initialState = {
    caseTypesByArea: null,
    caseStudy: null,
    chapterButtonSet: null,
    subRegionCasesByCaseType: null,
    referenceCases: null,
    expertOpinions: null,
    referenceCase: null,
    expertOpinion: null,
    regionSelected: null,
    caseStudy3d: null,
    selectedCaseType: 0,
    selectedInsight: 'Reference Cases',
    selectedInsightCategory: 1,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CASE_TYPES:
            return {
                ...state,
                caseTypesByArea: action.caseTypesByArea
            };
        case GET_CASE_STUDY:
            return {
                ...state,
                caseStudy: action.payload
            };
        case GET_CHAPTER_BUTTON_SETS:
            return {
                ...state,
                chapterButtonSet: action.payload
            };
        case GET_SUB_REGION_CASES_BY_CASE_TYPE:
            return {
                ...state,
                subRegionCasesByCaseType: action.subRegionCasesByCaseType
            };
        case GET_REFERENCE_CASES:
            return {
                ...state,
                referenceCases: action.referenceCases
            };
        case GET_EXPERT_OPINIONS:
            return {
                ...state,
                expertOpinions: action.expertOpinions
            };
        case GET_REFERENCE_CASE_BY_ID:
            return {
                ...state,
                referenceCase: action.referenceCase
            };
        case GET_EXPERT_OPINION_BY_ID:
            return {
                ...state,
                expertOpinion: action.expertOpinion
            };
        case SELECT_REGION:
            return {
                ...state,
                regionSelected: action.payload
            }
        case GET_3D_CASE_STUDY:
            return {
                ...state,
                caseStudy3d: action.payload
            }
        case SET_SELECTED_CASE_TYPE:
            return {
                ...state,
                selectedCaseType: action.selectedCaseType
            };
        case SET_SELECTED_INSIGHT:
            return {
                ...state,
                selectedInsight: action.selectedInsight,
                selectedInsightCategory: action.selectedInsightCategory
            };
        default:
            return state;
    }
}  