import React from 'react';

import {
    GET_PAPERS,
    FILTER_PAPERS,
    SET_CATEGORY_SELECTED,
} from '../actions/types';

var fake_papers =process.env.REACT_APP_IS_STAGING === "true" ? []: [
    {
        "id": 1,
        "title": "Azioni concrete per aumentare qualità  e transparenza in Medicina",
        "authors": "P. Regazzoni, A. Fernandez, P. Kleist and S.M. Perren",
        "issued_by": "GIOT",
        "issued_by_icuc": false,
        "date": "jun.-13",
        "abstract": "Misure per il miglioramento della qualità  dei servizi sani-tari oppure per garantire una ricerca di qualita sono slo-gan usati sovente nelle discussioni sulla politica sanitaria. Senza dubbio, il progresso dell assistenza sanitaria dipende molto dal controllo della qualita. La creazione di corrispondenti associazioni e gruppi di lavoro sono un segno di presa di coscienza dei problemi.",
        "url": "",
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 2,
        "title": "Ein neuer weg zum erlernen und Analysieren von Chirurgischen en Eingriffen",
        "authors": "P. Regazzoni, N. Suedkamp, A. Fernandez and S.M. Perren",
        "issued_by": "OUMN 2015 (4) - DGOU",
        "issued_by_icuc": false,
        "date": "aug.-15",
        "abstract": "Mit Hilfe moderner Informationstechnologie und mit qualitativ hochstehenden, detaillierten Nahaufnahmen einer groÃŸen Zahl von FÃ¤llen aus vollstÃ¤ndigen Serien chirurgischer Eingriffe aus verschiedenen Zentren entstand das ICUC app Das Lernen wird verbessert, indem unter anderem anonym, technische Probleme und suboptimale Resultate gezeigt werden. Die Gratis-Applikation erlaubt sowohl individuelles Lernen. die Vorbereitung von Eingriffen als auch den Einsatz bei Fallbesprechungen.",
        "url": "",
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 3,
        "title": "A new way to learn and analyze surgical interventions",
        "authors": "P. Regazzoni, N. Suedkamp and A. Fernandez, S.M. Perren",
        "issued_by": "Orthopeadics and Trauma Surgery Mess & News",
        "issued_by_icuc": false,
        "date": "aug.-15",
        "abstract": "The ICUC app aims at enhancing learning. To reach this objective the technical details of surgical interventions are illustrated with high-quality close ups of a large number of consecutive cases from different centers. Modern information technology is instrumental in handling the large amount of data. The quality of the technical performances covers a large spectrum. Therefore, the cases are labelled as recommended, to be discussed and not recommended. Suboptimal results are included as they are an excellent source of learning. Frank expert commentaries are encouraged by preserving patient and hospital anonymity. Videoclips are added to illustrate special aspects, tricks and hints for the procedures. The free app allows both individual learning and the preparation of presentations.",
        "url": "",
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 4,
        "title": "Fracture Fixation Using Cerclage, Research Applied to Surgery",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Acta Chir Orthop Traumatol Cech.",
        "issued_by_icuc": false,
        "date": "2015",
        "abstract": "The use of cerclage was condemned for decades based on the erroneous assumption that the cerclage would strangulate bone circulation. We understand today that most of the failures attributed to vascular strangulation were due to a lack of understanding of the biomechanics and biology of fixation and the application thereof. Figs 1 and 2 are examples of recent successful applications of cerclages to fulfill different functions in fracture fixation. The cases shown are extracted from the ICUC database of continuous, complete, unchanged and audited recordings. In the following the basic and special mechanics and biomechanics as well as biological aspects and application are dealt with in so-called one page papers.",
        "url": "http://www.achot.cz/detail.php?stat=802",
        "Biomechanics": "x",
        "Cerclage": "x",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 5,
        "title": "Balancing Success and Risk in Orthopedic Trauma Surgery",
        "authors": "P. Regazzoni, S.M. Perren and A. Fernandez",
        "issued_by": "Acta Chir Orthop Traumatol Cech.",
        "issued_by_icuc": false,
        "date": "2016",
        "abstract": "Immediate post-operative rating of surgical performance can be a valuable source of learning when trying to analyze the reasons for the difference between \"work as planned\" and \"work as done\". There are many reasons for the difference, but they can only be found if complete documentation of the surgical steps allows retrospective scrutiny. Documentation like ICUC provides this opportunity for scrutiny and may allow better understanding of some unexpected post-operative evolutions.",
        "url": "http://www.achot.cz/detail.php?stat=806",
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 6,
        "title": "Evolution of AO Fracture Treatment / Part 1: the Internal Fixator",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Acta Chir Orthop Traumatol Cech.",
        "issued_by_icuc": false,
        "date": "2017",
        "abstract": "Surgical fracture treatment has undergone an extensive evolution in the past decades. In the early days achieving solid healing in anatomically reduced position was the primary and nearly exclusive goal of fracture treatment. Since mainly Lambotte, Danis and Muller in Europe the focus of surgical fracture treatment shifted to achieving early recovery of the function of the injured limb with safe healing. Considering the shortcomings of the early fracture treatment helps understanding the evolution of surgical fracture treatment. The evolution of the biomechanical and biological principles of AO plate fixation are discussed as a model.",
        "url": "",
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 7,
        "title": "Fracture fixation using cerclages",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 1",
        "issued_by_icuc": true,
        "date": "sept.-15",
        "abstract": "The use of cerclages was condemned during decades based on the erroneous assumption that the cerclage would strangulate bone circulation. We understand today that most of the failures attributed to vascular strangulation were shortcomings of understanding biomechanics and biology of fixation and the application thereof. Fig. 1 and 2 are samples of recent successful application of cerclages fulfilling different functions in fracture fixation. The cases shown are extracted from the ICUC database of continuous, complete, unchanged and audited recordings. In the following the basic and special mechanics and biomechanics as well as biological aspects and application are dealt with in so called one-page papers.",
        "url": 1,
        "Biomechanics": "x",
        "Cerclage": "x",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 8,
        "title": "Balanced fixation in the surgical treatment of long bone fractures",
        "authors": "S.M. Perren, S. Lambert, P. Regazzoni and A. Fernandez",
        "issued_by": "Newsletter 2",
        "issued_by_icuc": true,
        "date": "oct.-15",
        "abstract": "Balance in internal fixation concerns first of all the balance between efforts to stabilize a fracture and the additional tissue trauma they will cause. That is, if the surgeon insists on or needs to achieve a very stable fixation (for instance by applying lag screws inserted from a direction more or less out of the surgical approach field), he may pay a high price in damage to the blood supply. This aspect will be dealt with in a subsequent ICUC one page. Here we address biomechanical basics first and, clinical applications to improve stabilization second.",
        "url": 2,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 9,
        "title": "The issue of mixing principles in internal fixation",
        "authors": "S.M. Perren, R. Babst, S. Lambert, P. Regazzoni and A. Fernandez",
        "issued_by": "Newsletter 3",
        "issued_by_icuc": true,
        "date": "nov.-15",
        "abstract": "The expression mixing principles suggests a procedure that is in dispute. The issue concerns the two basic but different functions of implants. On the one hand we use implants that produce and depend upon absolute stability such as lag screws. On the other hand we take advantage of implants which do allow a degree of instability, like splinting plates. At first glance combining the two different functions is incompatible. Nevertheless, for instance, protecting a lag screw with a splinting plate is a standard procedure. Whether mixing is advantageous or not depends entirely upon which kind of implant-functions are combined, and how and why they are combined, the sequence of steps at application may play a role.",
        "url": 3,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "x",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 10,
        "title": "Locked screws - Aspects of application and performance",
        "authors": "A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 4",
        "issued_by_icuc": true,
        "date": "feb.-16",
        "abstract": "Some aspects of self-drilling and self-tapping screws may need clarification. Especially the function of the mono-cortical screw is often open to discussion. In this paper, we discuss the different types of self-drilling and self-tapping screws, self-drilling bi-cortical screws - problems and solutions and finally, mono and bi-cortical screws under torque and bending applied to the plate/bone construct.",
        "url": 4,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 11,
        "title": "Biomechanical and biological aspects of defect treatment in fractures using helical plates",
        "authors": "A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 5",
        "issued_by_icuc": true,
        "date": "mar.-16",
        "abstract": "A clinical case in the ICUC App (ICUC case ID: 32-CO-538) shows an impressive series of consecutive plate fixation failures. The plates were repeatedly applied bridging a comminuted bone segment. The problems are discussed from a biomechanical viewpoint. The question of whether one should use a nail or plate, or whether there should be a bone graft and/or shingling are not addressed here.",
        "url": 5,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 12,
        "title": "Fatigue resistance",
        "authors": "P. Regazzoni, S.M. Perren and A. Fernandez",
        "issued_by": "Newsletter 6",
        "issued_by_icuc": true,
        "date": "apr.-16",
        "abstract": "Internal fixation (IF) is mandatory for fractures of the adult femur shaft. Nails are the implants most frequently used. Rotational mal-alignments and delayed or non-unions are possible complications.Plates are an additional option for IF, e.g. when knee or hip prosthesis do not allow the use of nails. IF with plates are at risk of implant failure (plate stripping screw breakage or fatigue), if the fracture is under persistent excessive load and does not consolidate within the usual time frame. Multi-fragmentary fractures with lack of bony buttress opposite to the plate are particularly at risk. Necessary reoperations are often technically demanding and induce an important systemic response of the patients.",
        "url": 6,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 13,
        "title": "Mixing a lag screw with a splinting protection plate",
        "authors": "P. Regazzoni, S.M. Perren and A. Fernandez",
        "issued_by": "Newsletter 7",
        "issued_by_icuc": true,
        "date": "may.-16",
        "abstract": "The 20th century has seen three essential improvements of surgical fracture fixation: 1) Shifting attention from mere fracture healing to quickly recovering function minimized dystrophic complications. 2) Shifting attention from pure mechanics of fixation to minimally invasive surgery optimized biology. 3) Shifting attention from mobility to tissue deformation that allowed improved handling of flexibly locked fixation. Now the combined use of splinting and compressive technology requires reappraisal of biomechanical aspects avoiding possible incompatibilities of different implant functions.",
        "url": 7,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 14,
        "title": "Considering surgical invasiveness",
        "authors": "P. Regazzoni, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 8",
        "issued_by_icuc": true,
        "date": "jun.-16",
        "abstract": "Accident surgery is an obvious example of surgical invasiveness: Tissue trauma reduces resistance to infection and impairs fracture healing. Reducing surgical invasiveness is therefore a primary goal...",
        "url": 8,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 15,
        "title": "Helix-shaped Plates for Distal fractures on the Humeral Shaft",
        "authors": "M. Reimundo, S. Sapriza, A. Fernandez and S. Lambert",
        "issued_by": "Newsletter 9",
        "issued_by_icuc": true,
        "date": "jul.-16",
        "abstract": "Wedge fractures of the distal part of the humeral shaft can be operated through a posterior or an anterior approach...",
        "url": 9,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 16,
        "title": "The treatment of trochanteric fractures revisited",
        "authors": "P. Regazzoni, A. Fernandez, D. Heim and S.M. Perren",
        "issued_by": "Newsletter 10",
        "issued_by_icuc": true,
        "date": "sept.-16",
        "abstract": "An optimal treatment of hip fractures is crucial because of the great number of involved patients suffering as well as for the large health care costs generated. This month the ICUC Paper focuses on examples of clinical problems and tips to avoid common errors.",
        "url": 10,
        "Biomechanics": "",
        "Cerclage": "x",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": "x"
    },
    {
        "id": 17,
        "title": "Brachialis Nerve Supply: On review of data in the ICUCÂ® App database",
        "authors": "S. Lasa, S. Lambert, P. Regazzoni, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 11",
        "issued_by_icuc": true,
        "date": "oct.-16",
        "abstract": "This month's ICUC paper illustrates that the data in the ICUC app constitutes more than a case series. It also offers the possibility of extracting information, thanks to complete documentation with a significant number of high quality images. This Information can offer anatomical knowledge, as in this month's paper on Brachialis nerve supply and can document the quality of soft tissue handling.",
        "url": 11,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 18,
        "title": "Titanium vs Steel in Trauma Surgery | Part I: Mechanical aspects",
        "authors": "S.M. Perren, P. Regazzoni and A. Fernandez",
        "issued_by": "Newsletter 12",
        "issued_by_icuc": true,
        "date": "nov.-16",
        "abstract": "The street opinions regarding the use of steel versus titanium as a surgical trauma implant material differ widely. Statements of opinion leaders range from I do not see any difference in the biological behavior between steel and titanium in clinical application.to I successfully use titanium implants in infected areas in a situation where steel would maintain infection. Furthermore, some comments imply that clinical proof for the superiority of titanium in human application is lacking. The following tries to clarify the issue addressing the different aspects more through a practical clinical approach than a purely scientific one. This includes simplifications.",
        "url": 12,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 19,
        "title": "Titanium vs Steel in Trauma Surgery | Part II: Biological aspects",
        "authors": "S.M. Perren, P. Regazzoni and A. Fernandez",
        "issued_by": "Newsletter 13",
        "issued_by_icuc": true,
        "date": "dic.-16",
        "abstract": "When choosing between the materials of metal implants the surgeon needs to balance the pros and cons mainly of steel and titanium. The arguments for the use of the preferred metal vary. The present paper elucidates the practical aspects based on the complex scientific background that has identified the differences between the two metals in their biological behavior. The data presented here are intended to help the surgeon when he is confronted with different and often complex clinical situations.",
        "url": 13,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 20,
        "title": "Fixation of Dorsal Ulnar Corner Fractures of the Distal Radius Through a Single Anterior Approach: A Prospective Study with CT Documentation",
        "authors": "M.J. Alvarez, J. Lans, A. Fernandez and J. Jupiter",
        "issued_by": "Newsletter 14",
        "issued_by_icuc": true,
        "date": "jan.-17",
        "abstract": "Displaced dorsal ulnar corner fractures are unique challenges because they affect both radiolunate and distal radioulnar (DRU) joints. Operative treatment may require both volar and dorsal approaches. While it has been reported about volar approach to dorsal lunate components, we show how the ball-tipped reduction clamp facilitates this without requiring excessive stripping of the volar radius to rotate the proximal fragment, as described by Orbay. This technique can be done by most surgeons through the well-established volar FCR approach.",
        "url": 14,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 21,
        "title": "The potential of the ICUC documentation concept Part I: Soft tissue handling",
        "authors": "P. Regazzoni, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 15",
        "issued_by_icuc": true,
        "date": "feb.-17",
        "abstract": "Today's documentation of surgical fracture treatment is wide open to improvement. The standard documentation - radiological images and possibly some additional intra-operative images of the surgical field - does not provide realistic information about the fundamental aspects of technical performance. Written reports and pre- and post-operative X-rays do not document the quality of tissue handling by the surgeon, can omit or even distort important aspects of the procedure and make retrospective judgment difficult. Improper handling of tissues and technical tools is a frequent source of complications or difficult post-operative courses concerning the skin and the deeper tissues including the bone. Therefore, without full documentation it is difficult to understand why certain complications occur. A complete documentation, according to the ICUC-concept, is not simple to realize, but is far more transparent.",
        "url": 15,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 22,
        "title": "The potential of the ICUC documentation concept Part II: Radiation monitoring",
        "authors": "P. Regazzoni, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 16",
        "issued_by_icuc": true,
        "date": "mar.-17",
        "abstract": "Radiation hazards during surgery is a further field where improvements, using the ICUC documentation concept, are possible. Intra-operative fluoroscopy is a routine in surgical fracture treatment; the ease of C-arm controls helps to improve suboptimal fracture reduction but seduces to radiation overuse. Standard documentation, where only selected fluoroscopy shots \"if any\" are archived, underestimates radiation hazards. According to the ICUC documentation principles all fluoroscopy shots and images of the C-arm positioning are included. Thereby, adherence to as low as reasonably achievable (ALARA) rules as well as proper handling of the C-arm can be checked.",
        "url": 16,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 23,
        "title": "The ICUC app: Can it pave the way for quality control and transparency in medicine?",
        "authors": "P. Regazzoni, P.V. Giannoudis, S. Lambert, A. Fernandez and S.M. Perren",
        "issued_by": "Injury, Int. J. Care Injured 48 (6)",
        "issued_by_icuc": false,
        "date": "jun.-17",
        "abstract": "Criticism of todays Health Care Systems range from scientific misconduct and insufficient documentation of clinical activity to unnecessary, or inappropriate surgery and inadequate learning tools. An open source electronic learning tool for surgical procedures can help to maintain scientific independence, improve quality and increase user comfort. Such a source of learning has been recently developed: the ICUC app. The tool is based on Continuous and Complete data of the technical performance of surgical procedures. This guarantees that no shortcomings or errors are missed as they are also a valuable source of learning. Its use could enhance progress in surgical education in a similar way the flight recorder did in aviation. Subsequently, the lessons from learning might expand to other health care fields like documentation in research and clinical medicine and health care management.",
        "url": "",
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 24,
        "title": "Clinical relevance of mechano-biological foundations of implant coupling to bone",
        "authors": "A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 17",
        "issued_by_icuc": true,
        "date": "apr.-17",
        "abstract": "Controlling the biomechanical conditions of the implant to bone interface is key to clinical success when based on the understanding of bone reaction. The function of implants in fracture treatment depends on coupling between bone and implant. On the one hand loss of coupling between implant and bone compromises or abolishes the stabilizing function of the implant. Such loosening is most frequently the result of bone surface resorption at the interface. On the other hand, maintained tight coupling over years would, according to law of Wolff, cause unfavorable bone loss.",
        "url": 17,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 25,
        "title": "From Diagnosis to Therapy Planning",
        "authors": "P. Regazzoni, A. Pace, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 18",
        "issued_by_icuc": true,
        "date": "may.-17",
        "abstract": "A detailed pre-operative plan must be based on the precise recognition and definition of the problems inventory for a given case. Adding a problems inventory to a simple group classification is more useful than insisting on classification sub-groups that do not consider influential details which require attention and adequate treatment. Problems are identified by thorough analysis of patient data, anamnesis and adequate imaging. A generous use of pre-operative CTs is helpful to better understand the details of a given lesion and avoid to discover them only intra-operatively. A few detailed ankle fractures from the ICUC App have been chosen to illustrate the above statements.",
        "url": 18,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 26,
        "title": "Evolution of Bone Screws with special reference to AO screws | Part I: From history to DCP",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 19",
        "issued_by_icuc": true,
        "date": "jun.-17",
        "abstract": "Initially bone screws were mainly used for attaching plates to bone. Pressing the plate to the bone surface was the sole function of such plate screws; therefore, the undersurface of the screw head was flat. The screw thread outline was conical like in wood screws. The Danis plate, the precursor to the AO screws, took advantage of a cylindrical thread outline and its single thread element was asymmetrical with a perpendicular surface in relation to the axial force exerted by the screw. The AO screw developed by M.E. Muller and R. Mathys resolved several shortcomings. The thread shape INBUS. The next step in the evolution of the AO plate and screw was initiated installing a slope that allowed to produce compression without additional device.",
        "url": 19,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 27,
        "title": "Evolution of Bone Screws with special reference to AO screws | Part II: Locked Plate Screws",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 20",
        "issued_by_icuc": true,
        "date": "jul.-17",
        "abstract": "The goal of modern fracture treatment is to regain function early with optimal biology and induction of fracture healing. Today, the tool to achieve this goal is the internal fixator. While absolute stability of surgical fracture fixation allows early function and healing in mechanically neutral condition, it lacks proper induction of the repair process. Implant contact damages blood supply, which results in early temporary bone loss and possible sequestration. Minimizing or preventing contact at least of the main splint is a biological goal. This goal is achieved using locked plate screws.",
        "url": 20,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 28,
        "title": "Evolution of Bone Screws with special reference to AO screws | Part III: Coupling quality of locked plate screws",
        "authors": "M.J. Alvarez, A. Acosta, A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 21",
        "issued_by_icuc": true,
        "date": "aug.-17",
        "abstract": "To understand non-expected behaviour of fracture healing, it is essential to know the characteristics of mechanical coupling, and with it, the function of implants. Do locked screws loose coupling and, if so, when? A simple answer regarding loosening conditions is not possible. We therefore report the observation without explanation, abstaining from conclusions. The observations should stimulate assessment by the reader.",
        "url": 21,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 29,
        "title": "Variable Angle Locked Screw (VAL): from concept to application",
        "authors": "A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 22",
        "issued_by_icuc": true,
        "date": "sept.-17",
        "abstract": "Locked plate-screw constructs, a technology that improves biology of internal fixation, is widely accepted. Understanding of biology and biomechanics is a prerequisite to need to provide solid and maintained anchorage to plate and to bone. Fixed angle locked screws (FAL) do function only when perfectly aligned with the axis of the screw hole while VAL allows to extend the range of inclination to about 15 degrees making the technique more forgiving.",
        "url": 22,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 30,
        "title": "Delayed Healing / Non-healing after IM nailing",
        "authors": "P. Regazzoni, S.M. Perren and A. Fernandez",
        "issued_by": "Newsletter 23",
        "issued_by_icuc": true,
        "date": "oct.-17",
        "abstract": "Nailing is the preferred treatment of femur shaft fractures. For delayed unions or non-unions reamed exchange nailing and plate augmentation, used as tools to increase construct stiffness, seem to be the treatment of choice. Dynamization should not be considered anymore. In view of the general effects of medullary reaming, possibly less invasive alternatives might be interesting. Simply adding an internal fixator, by minimally invasive techniques, leaving the nail in place could be such a technically simple alternative.",
        "url": 23,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 31,
        "title": "Infection after internal fixation / Part 1: Biomechanics",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 24",
        "issued_by_icuc": true,
        "date": "nov.-17",
        "abstract": "When a surgeon faces a postoperative infection the next step of treatment is critical in respect to avoidance of a chronic infection and to reach safely and promptly healing and function. We discuss here first biomechanical elements that need to be considered because they play an important role.",
        "url": 24,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 32,
        "title": "Infection after internal fixation / Part 2: Biology",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 25",
        "issued_by_icuc": true,
        "date": "dic.-17",
        "abstract": "Effective treatment of infection requires understanding and careful application of research data. Each element on its own may only have a limited effect but the effect of the sum cannot be disregarded. Certain issues are generally well understood: the relevance of blood supply, tissue tolerance of the implant material and biofilm. In the following the origin of sequestration and prevention of biofilm are discussed. Tissue adherence deserves special attention.",
        "url": 25,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 33,
        "title": "Unstable trochanteric fractures",
        "authors": "P. Regazzoni, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 26",
        "issued_by_icuc": true,
        "date": "jan.-18",
        "abstract": "For this fracture type the literature and guidelines present nailing as a panacea. Both nails and plates used for proximal femur fractures, can produce undesired effects. Therefore, we can ask yourselves: When and how does cut-out occur? When and how does cut-in occur? When and how does implant fatigue occur? Can excessive telescoping be limited? Should cerclage be used? If so, before or after guide pin insertion? Is rotation of the head and neck fragment a real problem? The above questions and more shall be addressed during further newsletters, making use of the case analysis existing on the ICUC database.",
        "url": 26,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 34,
        "title": "Clarifying terminology of trochanteric fractures",
        "authors": "P. Regazzoni, S.M. Perren and A. Fernandez",
        "issued_by": "Newsletter 27",
        "issued_by_icuc": true,
        "date": "feb.-18",
        "abstract": "The terms used for specifying trochanteric fractures and their problems need clarification because certain terms are used indiscriminately for very different fractures and follow states. In a sequence of newsletters, we discuss these problems using cases from the ICUC database for illustration.",
        "url": 27,
        "Biomechanics": "",
        "Cerclage": "x",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 35,
        "title": "Cerclage in internal fixation of trochanteric fractures",
        "authors": "P. Regazzoni, S.M. Perren, A. Fernandez and commented by the ICUC Experts",
        "issued_by": "Newsletter 28",
        "issued_by_icuc": true,
        "date": "mar.-18",
        "abstract": "Trochanteric fractures pose difficult problems regarding reduction and maintenance thereof cerclage is an excellent tool, but its efficient use depends on type and orientation of the fracture. 3D fracture models with a color-coded segmentation to visualize the different fragments can be analyzed in a 360 degrees view, which allows to come up with a solid and reliable decision whether the cerclage can be used for reduction and/or maintenance.",
        "url": 28,
        "Biomechanics": "",
        "Cerclage": "x",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 36,
        "title": "Description and Understanding of proximal femur fractures using coloured 3D models",
        "authors": "P. Regazzoni, A. Fernandez, M. Berlusconi, A. Pace and S.M. Perren",
        "issued_by": "Newsletter 29",
        "issued_by_icuc": true,
        "date": "apr.-18",
        "abstract": "It is often difficult to understand and describe complex trochanteric fractures using conventional imaging. But understanding is paramount for a sound planning of the treatment. Using coloured 3D models is extremely helpful to understand complex fractures and define a problem list for their treatment. Unequivocal terms are helpful for the description of the initial fracture, the fixation construct and the following postoperative result and course.",
        "url": 29,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 37,
        "title": "Proximal femur fractures: telescoping, compaction and impaction",
        "authors": "A. Fernandez Dell'Oca, S.M. Perren and P. Regazzoni",
        "issued_by": "Newsletter 30",
        "issued_by_icuc": true,
        "date": "may.-18",
        "abstract": "Relative movements between implant parts and those between anatomical elements of the proximal femur require separate analysis for a differentiated understanding: sliding hip screws and proximal femoral nails are implants made of two elements: one or two screw(s) which should guide the head-and-neck fragment and either a nail or a side-plate. The design allows a controlled movement in relation to each other, this is 'telescoping' of the screw through the barrel of the plate or the hole of the nail. It therefore appeared to be obvious to call 'telescoping' all different events where the screw glides laterally. Unfortunately, such generic terms prevent a differentiated understanding.",
        "url": 30,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 38,
        "title": "Bone healing in simple fracture pattern",
        "authors": "S.M. Perren, P. Regazzoni and A. Fernandez",
        "issued_by": "Newsletter 31",
        "issued_by_icuc": true,
        "date": "jun.-18",
        "abstract": "Why add a lag screw? How much additional damage (bone denudation) is acceptable to place a lag screw? How do we know whether the lag screw only helps maintenance of reduction or produces compression? Whether a lag screw only helps to maintain reduction or produces compression during a longer period of time remains an interesting open question. By analyzing cases from the ICUC database, we attempt to demonstrate simple fracture pattern can heal under very different treatment conditions.",
        "url": 31,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 39,
        "title": "ICUC Scores",
        "authors": "S.M. Perren, P. Regazzoni and A. Fernandez",
        "issued_by": "Newsletter 32",
        "issued_by_icuc": true,
        "date": "jul.-18",
        "abstract": "Measuring the outcome is a precondition for improvement in surgery. To be efficient and reliable a score needs to address a defined question with a reliable application that is simple to handle. The ICUC score uses for all anatomical regions the same bear minimum of questions to allow assessing subjective, patient reported, outcome and adds objective data e.g. documenting range of motion with their postoperative evolution. Therefore, it differs from existing scores in respect to restricted goal, and to restricted depth of data, all without limitation to restricted anatomical regions.",
        "url": 32,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 40,
        "title": "Course of ICUC score and ICUC image documentation",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 33",
        "issued_by_icuc": true,
        "date": "aug.-18",
        "abstract": "Evaluation of treatment today usually relies on Patient Reported Outcome Measures (PROM). ICUC adds a concise and reliable score and a documentation concept that allows retrospective analysis. The ICUC Trauma Treatment (TT) score is: a tool to evaluate functional limitations and pain. The ICUC TT score is not: a compilation of questions vaguely related to the topic under discussion and with it clumsy to use: soon identified as inefficient and demotivating.",
        "url": 33,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 41,
        "title": "MIO helical plate: technically easy, improving biology and mechanics of double plating",
        "authors": "P. Regazzoni, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 34",
        "issued_by_icuc": true,
        "date": "sept.-18",
        "abstract": "A single plate, bridging a bone defect, e.g. in the distal femur, risks irreversible deformation or breakage. Double plating, served early on to protect by strengthening the construct. The mechanical advantage provided was uncontested, but the biological damage (both for the access for plate placement and the vascular damage by the old plate types, fixed to the bone by friction) was disturbing. The helical plate is an internal fixator (introduced with MIO technique) that requires no additional exposure of the fracture site, is technically easy to prepare and needs minimal additional exposure and it replaces the missing remote cortical support by acting as a kind of tension/compression band with good leverage; this is why it seems to be the adequate modern solution of double plating in distal femur, offering several advantages.",
        "url": 34,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 42,
        "title": "Double locking plate, surgical trauma and construct stiffness improved by the helical plate",
        "authors": "S.M. Perren, P. Regazzoni, M. Lenz and A. Fernandez",
        "issued_by": "Newsletter 35",
        "issued_by_icuc": true,
        "date": "oct.-18",
        "abstract": "The helical plate improves safety of double plate fixation of comminuted or defect fractures for instance of the distal femur. The application is simple, the effect efficient and the biology is maintained intact. The procedure requires the surgeon to accustom to a new practise that is simple and straight forward.",
        "url": 35,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 43,
        "title": "Biomechanical aspects of double plating using cases of the ICUC app",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 36",
        "issued_by_icuc": true,
        "date": "nov.-18",
        "abstract": "To reduce the load carried by the plate an additional plate may be used. The additional plate goes along with substantial additional trauma near or at the fracture site; the helical plate has been proposed as though an unusual but very efficient and atraumatic solution that requires only a small medial approach. Despite the unusual x-ray appearance, the helical plate offers essential biomechanical advantages. While the helical plate offers an efficient fixation for non-unions it offers best protection from the beginning on.",
        "url": 36,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 44,
        "title": "Biomechanical analysis of a bridge plating in a mal-reduced, open-gap fracture",
        "authors": "S.M. Perren, P. Regazzoni and A. Fernandez",
        "issued_by": "Newsletter 37",
        "issued_by_icuc": true,
        "date": "dic.-18",
        "abstract": "The following is a biomechanical discussion of a case from the ICUC® app (ID: 32-SI-372): A short oblique distal femur shaft fracture with a tiny intermediate fragment. The fracture was badly reduced. For what concerns biology the situation was initially poor...",
        "url": 37,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 45,
        "title": "Supplementing insufficient X-ray information",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 38",
        "issued_by_icuc": true,
        "date": "jan.-19",
        "abstract": "Conventional X-rays report topography of densities but misses information of gaps or parts of the gap surfaces that are not aligned with the beam. Biological information is rare and only retrospectively possible. CT has the power to clarify but keep in mind that the selection of slices may miss important information. The following open tibia fracture illustrates difficulties and limits of understanding the postoperative courses in complicated evolutions of fracture healing and to define further treatment such as weight bearing, re-interventions and alike.",
        "url": 38,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 46,
        "title": "Does locking last in metaphyseal screws with guided or free-hand insertion?",
        "authors": "A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 39",
        "issued_by_icuc": true,
        "date": "feb.-19",
        "abstract": "Recent ICUC data suggest that some screws inserted as locked were found to be unlocked at implant removal. In diffrent publications it was assumed that (fixed angle) locked screws stay durably locked when inserted correctly (with guidance). Lenz et al. demonstrated under laboratory conditions that even repeated load does not unlock the screw.",
        "url": 39,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 47,
        "title": "Uncontrolled unlocking of locked screws. Observations in PHILOS fixation of proximal humerus fractures",
        "authors": "A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 40",
        "issued_by_icuc": true,
        "date": "mar.-19",
        "abstract": "The principle of internal fixation by splinting consists of bridging the aligned fracture with a splint (implant) and can be realized with different implants like plate, nail fixator, cerclage, pins and wires. The following elements will be presented in cases with a plate fixed to the main fragments using locked screws: - Locking of screw head in the plate hole. - Loss of solid contact of the screw thread within the bone thread through bone resorption at the interface induced by micromotion. - Strength and stiffness of the plate. - Strength and stiffness of the screws.",
        "url": 40,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 48,
        "title": "The 45 degree distal locking technique (for solid and cannulated IM Nails)",
        "authors": "M. Berlusconi, A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 41",
        "issued_by_icuc": true,
        "date": "apr.-19",
        "abstract": "The history of nailing illustrates a long and steady evolution of materials, design aspects, introduction sites and biomechanical concepts. Clearly, modern closed nailing requires advanced intraoperative imaging. In addition, experienced OR personal is needed to manipulate the imaging modality for interlocking to avoid excessive radiation. Several sophisticated tools have been proposed during the last decades to facilitate precise interlocking respecting radiation protection. The method proposed in this newsletter is very helpful, especially when and where such tools and personal is not available.",
        "url": 41,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": ""
    },
    {
        "id": 49,
        "title": "Helical plate: The stress riser issue. The specific issue of implant and coupling loads",
        "authors": "M. Lenz, P. Varga, D. Mischler, B. Gueorguiev, A. Fernandez, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 42",
        "issued_by_icuc": true,
        "date": "may.-19",
        "abstract": "Comminuted diaphyseal femur fractures are at the basis of the present discussion. This paper addresses the concern that was voiced in respect to what was called stress rising as a result of nearby coupling the proximal ends of two plates to bone. The goal of the present study was to observe the loading of different plate combinations: single lateral plate, single lateral plate + antero-medial parallel plate, single lateral plate + helical plate. The result of the qualitative observation indicates a large loading of the single lateral plate, that was essentially reduced by the added parallel and similarly helical plate. The loading of the helical plate was minimal.",
        "url": 42,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 50,
        "title": "ICUC Biomechanics pills for orthopaedic practitioner 1 Stability, stiffness and strength",
        "authors": "P. Regazzoni, M. Berlusconi, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 43",
        "issued_by_icuc": true,
        "date": "jun.-19",
        "abstract": "Implants are designed to provide proper function under standard conditions of surgical fixation. Varus malalignment open fracture gap, excessive body mass and perhaps uncontrolled weight bearing are the culprits in combination exceeded that demand of strength of the implant. Clarifying simple aspects of clinical cases, will finally result in better understanding and with it in improved treatment.",
        "url": 43,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 51,
        "title": "Biomechanics of non union, an unusual case discussing also the different aspects of the term inestability of internal fixation",
        "authors": "S.M. Perren, A. Fernandez and P. Regazzoni",
        "issued_by": "Newsletter 44",
        "issued_by_icuc": true,
        "date": "jul.-19",
        "abstract": "The case presented here triggered discussing biomechanics and mechanobiology accepting tissue reaction avoiding pseudo-teleological interpretation. To avoid confusion the term stability should be defined.",
        "url": 44,
        "Biomechanics": "x",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": "x"
    },
    {
        "id": 52,
        "title": "Removing screws after stripping of drive connection",
        "authors": "A. Fernandez, N. Otero, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 45",
        "issued_by_icuc": true,
        "date": "aug.-19",
        "abstract": "The proposed solution consists in cutting off the screw head from the remaining thread shaft connection. It is a procedure that involves a combination of drilling and milling and resolves the problem, but requires careful planning and execution.",
        "url": 45,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "x",
        "Complications": "x"
    },
    {
        "id": 53,
        "title": "Interactive CT-based 3D colored models as a tool to help in the forecast of AVN risk in proximal humerus fractures",
        "authors": "A. Fernandez, M. Jaeger, S. Lasa, N. Suedkamp, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 45-1",
        "issued_by_icuc": true,
        "date": "sept.-19",
        "abstract": "The use of interactive CT-based 3D colored models might be an additional tool to better understand fracture surfaces, fragment displacement and morphological fragment details and addressing the above questions and defining surgical strategies, i.e. internal fixation or prosthetic replacement.",
        "url": "45-1",
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 54,
        "title": "Hanging arm in the Rehab Protocol of PHILOS plating",
        "authors": "A. Fernandez, M. Jaeger, N. Suedkamp, S. Lasa, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 45-2",
        "issued_by_icuc": true,
        "date": "sept.-19",
        "abstract": "The hanging arm protocol consists in encouraging the patient in the immediate post-operative period after PHILOS plating to lean forward very slowly like trying to reach the toes with the finger tips and again very slowly getting up, as long as the procedure is completely painless.",
        "url": "45-2",
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 55,
        "title": "Hanging arm in the non-operative treatment of proximal humerus fractures",
        "authors": "A. Fernandez, M. Jaeger, N. Suedkamp, F. Wagner, C. Hughes, P. Regazzoni and S.M. Perren",
        "issued_by": "Newsletter 45-3",
        "issued_by_icuc": true,
        "date": "sept.-19",
        "abstract": "The hanging arm protocol consists in encouraging the patient since day one to lean forward very slowly trying to reach the toes with the finger tips and again very slowly getting up, as long as the procedure is completely painless. The hanging arm protocol aims at keeping a totally mobile shoulder joint using only gravity in order to avoid motion at the fracture site, which may lower non-healing risk and may increase the functional outcome.",
        "url": "45-3",
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 56,
        "title": "Plating shaft fractures: Lag screws or cerclages or just bridging?",
        "authors": "P. Regazzoni, A. Fernandez and S.M. Perren",
        "issued_by": "Newsletter 46",
        "issued_by_icuc": true,
        "date": "sept.-19",
        "abstract": "The majority of shaft fractures of the lower leg are a nailing domain. If, for any reason, plating is used in simple fracture types, the surgeon has to weigh up advantages, technical feasibility and invasiveness according to the fracture morphology. Flexible fixation (bridging) or motionless fixation (compression) are viable options. In any case the reduction should be as good as possible.",
        "url": 46,
        "Biomechanics": "x",
        "Cerclage": "x",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "x",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 57,
        "title": "Fracture visualisation: Interactive, colored 3D models",
        "authors": "P. Regazzoni, M. Berlusconi, A. Pace, A. Fernández and S.M. Perren",
        "issued_by": "Newsletter 48",
        "issued_by_icuc": true,
        "date": "nov.-19",
        "abstract": <div>
            <div>Planning the treatment of a fracture starts usually with conventional X-rays. But is this enough? </div>
            <div>- CTs are needed to understand complex fractures</div>
            <div>- surgeons must scroll through entire 3D data to reach a correct cognition</div>
            <div>- interactive models are a step forward</div>
            <div>- coloured models further simplify cognition</div>
            <div>- interactive, coloured 3D models are today’s best solution (if production is technically correct)</div>
        </div>,
        "url": 47,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 58,
        "title": "Description of fractures for communication",
        "authors": "Alberto Fernández, Marco Berlusconi, Antonio Pace, Stephan Perren and Pietro Regazzoni",
        "issued_by": "Newsletter 49",
        "issued_by_icuc": true,
        "date": "dic.-19",
        "abstract": "Communication about fractures is best done by image exchange, but this is not always possible. Pure verbal descriptions of fractures are used in medical reports. However, disagreements exist among different observers about the relevance of the characteristics used for grouping and diverging conclusions might be drawn. The search of new characteristics, relevant for treatment and prognosis is therefore justified. A way to reach this goal is to describe and analyse many single cases with similar behaviour. The use of universally accepted references like anatomical parts for the description avoids misunderstandings.",
        "url": 48,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 59,
        "title": "Scapho-lunate Dissociation associated to a Distal radius fracture",
        "authors": "Alejandro Lasa, Jesse Jupiter",
        "issued_by": "Newsletter 50",
        "issued_by_icuc": true,
        "date": "jan.-20",
        "abstract": "When scapholunate diastasis is detected, the opposite may show a similar finding, so bilateral imaging is important. This case demonstrates the basic need to X-ray the opposite wrist as part of the preoperative planning.",
        "url": 49,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 60,
        "title": "Bridge plate in DRF Revisited commented by Professor Jesse B. Jupiter",
        "authors": "Jesse Jupiter, Alberto Fernández",
        "issued_by": "Newsletter 51",
        "issued_by_icuc": true,
        "date": "feb.-20",
        "abstract": "When to use this technique and how to perform it - Case analysis ",
        "url": 49,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 61,
        "title": <div>
            <div>Comparing plan and result in Tibia Plateau fractures - Case analysis</div>
        </div>,
        "authors": "P. Regazzoni, T. Nizegorodcew, A.Fernández",
        "issued_by": "Newsletter 52",
        "issued_by_icuc": true,
        "date": "may.-20",
        "abstract": <div>
            <div>Preoperative data help to define the problem list of a case and define the treatment plan.</div>
            <div>Based on the list of problems, usually, different, valid solution options exist.</div>
            <div>If there are differences between the preoperative plan and the immediate result of surgery difficult questions arise.</div>
            <br/>
            <div>What does the result teach me for my next surgery of a similar case?</div>
        </div>,
        "url": 49,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "",
        "Screws": "",
        "Plating": "x",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 62,
        "title": "Trochanteric fractures: Does reduction matter?",
        "authors": "P. Regazzoni, T. Nizegorodcew, A.Fernández",
        "issued_by": "Newsletter 53",
        "issued_by_icuc": true,
        "date": "may.-20",
        "abstract": <div>
            <div>Many questions are brought up with the following case.</div>
            <br/>
            <div>The goal is not to give straight forward answers but to enhance cognitive reflection and deep learning.</div>
            <br/>
            <div>There might be different valid answers and also remaining questions!</div>
        </div>,
        "url": 49,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "x",
        "ICUC": "",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 63,
        "title": "Joint depression in tibial plateau fractures: To bone graft or not bone graft? IMAGE GALLERY",
        "authors": "M. Hartwich, MD, J. Lans, MD, PhD J. B. Jupiter, MD, R. Babst, MD, P. Regazzoni, A. Fernández Dell’Oca, MD",
        "issued_by": "",
        "issued_by_icuc": true,
        "date": "jul.-22",
        "abstract": "",
        "url": 49,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 64,
        "title": "Risk of reduction loss after volar plating alone in Volar Rim fractures (AO B type/C type) IMAGE GALLERY",
        "authors": "A. Gavagnin, A. Bhashyam, A. Fernández, J. Jupiter, P. Regazzoni",
        "issued_by": "",
        "issued_by_icuc": true,
        "date": "apr.-22",
        "abstract": "",
        "url": 49,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
    {
        "id": 65,
        "title": "Intra-op 3D Imaging vs Conventional CT IMAGE GALLERY",
        "authors": "J. Eguiguren, M. Lemos, L. Chiquiar, A. Liu, A. Fernández, J. Jupiter, P. Regazzoni",
        "issued_by": "",
        "issued_by_icuc": true,
        "date": "mar.-23",
        "abstract": "",
        "url": 49,
        "Biomechanics": "",
        "Cerclage": "",
        "Interactive 3D models": "",
        "ICUC": "x",
        "Screws": "",
        "Plating": "",
        "Surgical technique": "",
        "Complications": ""
    },
]

const initialState = {
    papers: fake_papers.reverse(),
    categorySelected: '',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAPERS:
            return {
                ...state,
                papers: []
            };
        case SET_CATEGORY_SELECTED:
            return {
                ...state,
                categorySelected: action.categorySelected
            };
        case FILTER_PAPERS:
            let categoryToFilter = action.payload
            let filterPapers = []
            fake_papers.forEach((paper) => {
                let value = paper[categoryToFilter]
                if (value === 'x') filterPapers.push(paper)
            });

            let filterPapersReverseOrder = filterPapers
            return {
                ...state,
                papers: filterPapersReverseOrder
            }
        default:
            return state;
    }
}
