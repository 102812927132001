import React from 'react'
import '../input/input.css'
import './select.css'
import iconoFlechaAbajoGris from 'assets/icons/iconoFlechaAbajoGris.svg'

const CustomSelect = ({ name, value, options, placeholder, onChange = () => { }, error = false, border, selectWidth }) => {
    const borderStyle = { border: border ? error ? '1px solid red' : '1px solid black' : null }
    const styles = borderStyle
    let iconSize = 32

    return (
        <div className="customInputContainer"
            style={styles}>
            <div className="customInputInnerContainer">
                <select name={name} value={value} onChange={onChange} className="customSelectStyle" style={{width: selectWidth, cursor:  "pointer"}} >
                    <option value="" selected disabled>{placeholder}</option>
                    {options.map((option)=>{
                        return(
                            <option value={option}>{option}</option>
                        )
                    })}
                </select>
                <span className="arrowDisplaySelectInput">
                    <img src={iconoFlechaAbajoGris} width={iconSize} height={iconSize} />
                </span>
            </div>
        </div>
    )
}

export default CustomSelect