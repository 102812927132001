import axios from 'axios'
import { apiUri } from '../config'

let api = axios.create({
    baseURL: apiUri + '/api/v1/'
})

const routes = {
    //Auth
    auth: 'sessions',
    //Users
    users: 'users',
    forgot_password: apiUri + '/users/password',
    //Passwords
    passwords: 'passwords',
    //Regions
    case_types: '/case_types',
    case_studies: '/case_studies',
    chapter_button_sets: '/chapter_button_sets',
    reference_cases: '/reference_cases',
    expert_opinions: '/expert_opinions',

}

export { api, routes }
