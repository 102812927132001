import React from 'react'
import './Button.css'

const Button = ({ title, action = () => { }, disabled = false }) => {

    return (
        <button className="customBtn" onClick={action} disabled={disabled}>
            <span className="customBtnText">{title}</span>
        </button>
    )
}

export default Button