import queryString from 'query-string'
import {ipadWidth, desktopWidth} from 'utils/constants'

export function sortStringArrayAlphabetical(arr)
{
    arr.sort((A,B) => { return (A < B) ? -1 : ( (A > B) ? 1 : 0) })
}

export function sortArrayAlphabetical(arr)
{
    arr.sort((A,B) => { return (A.title < B.title) ? -1 : ( (A.title > B.title) ? 1 : 0) })
}

export function getQueryParam(props, queryName)
{
    let query = queryString.parse(props.location.search, {
        ignoreQueryPrefix: true
    });

    return query[queryName]
}

export function getElementByClass(element, position)
{
    return document.querySelectorAll(element)[position]
}

export function cleanNumberFromFracturalTypeName(title) //its only for some categories that has numbers at the beginning
{
    let numbersInTitles = ["1. ","2. ","3. ","4. ","5. ","6. ","7. "]

    for (let i = 0; i < numbersInTitles.length; i++) {
        const numberInTitle = numbersInTitles[i];
        
        title = title.replace(numberInTitle, "")
    }

    return title;
}


export function isDesktop(windowWidth)
{
    // return window.screen.width > ipadWidth
    // return windowWidth > ipadWidth
    return windowWidth > desktopWidth
}

export function checkIfDesktopVersion()
{
    // return window.screen.width > ipadWidth
    return window.screen.width > desktopWidth
}

export function getShareUrlCaseStudy(caseStudyId)
{
        
    let baseUrl =  window.location.origin
    return baseUrl + '/case-study/' + caseStudyId
}


