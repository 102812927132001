import React, { Component } from 'react';

import {WhatsappShareButton, WhatsappIcon,
	 	EmailShareButton, EmailIcon,
		TwitterShareButton, TwitterIcon,
		FacebookShareButton, FacebookIcon} from 'react-share'

import { MdClear } from 'react-icons/md';

import {BottomDiv} from 'components/common';

import 'css/shareBottomView.css'

class ShareBottomView extends Component {

	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render() {
		let {shareUrl} = this.props
		
		let singleShareButton = (name, ShareButton, ShareIcon) => {

			return (
				<div style={{marginBottom: "10px",display: "flex", width: "100%"}}>
						<ShareButton url={shareUrl} >
							<div style={{display: "flex", width: "100%"}}>
								<ShareIcon
								size={32}
								round />
								<div style={{display: "flex", alignItems: "center", marginLeft: "5px", fontWeight: "bold"}}>
									{name}
								</div>
							</div>
						</ShareButton>
					
				</div>	
			)
		}
		
		return (
			<BottomDiv style={{backgroundColor: "rgb(240, 240, 240)", padding: "30px 10px 20px 10px", borderRadius: "15px 15px 0px 0px", display: this.props.display ? "block" : "none" }}>
				
				{singleShareButton("Whatsapp", WhatsappShareButton, WhatsappIcon)}
				{singleShareButton("Email", EmailShareButton, EmailIcon)}
				{singleShareButton("Facebook", FacebookShareButton, FacebookIcon)}
				{singleShareButton("Twitter", TwitterShareButton, TwitterIcon)}
				
				<div style={{height: "1px", width: "100%", backgroundColor: "#e9e9e9", marginBottom: "10px" }}>
				</div>

				<span onClick={this.props.closeFunction} style={{cursor: "pointer"}}>
					<MdClear size={30} /> 
					<span style={{fontWeight: "bold", marginLeft: "3px"}}>
						Cancel
					</span>
				</span>

			</BottomDiv>
		);
	}
}

export default ShareBottomView;
