import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    UPDATE_USER,
} from '../actions/types';

//localStorage.setItem('item', )
//localStorage.setItem('userJson', '{"id": "5d9d00f3a728690aaf32be40", "location": "Uruguay", "date_of_birth": "1990-01-01T02:00:00.000Z","full_name": "mauricio","hospital": "hos","email": "gianpapa20@gmail.com","gender": "Male","position": "pos"}')

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: (localStorage.getItem('token') !== null),
    isLoading: false,
    user: (localStorage.getItem('userJson') !== null) ? JSON.parse(localStorage.getItem('userJson')) : null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            };
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            return {
                ...state,
                token: localStorage.getItem('token'),
                isAuthenticated: true,
                isLoading: false,
                user: JSON.parse(localStorage.getItem('userJson'))
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false
            };
        case UPDATE_USER:
            return {
                ...state,
                user: JSON.parse(localStorage.getItem('userJson'))
            };
        default:
            return state;
    }
}  