import React from 'react';
import './caseDetails.scss';

const CaseDetails = (props) => {

    const titleValueShow = (title, value) => {
        return (
            <div className="case-info-block">
                <div className="case-info-block-title">
                    {title}
                </div>
                <div className="case-info-block-value">
                    {value}
                </div>
            </div>
        )
    }
    const showInfo = () => {
        const caseStudy = props.caseStudy
        const infoBlock = (title, subtitle) => {
            const getHighlightedText = (text, words) => {
                let regex = '('
                for (var i = 0; i < words.length; i++) {
                    if (i > 0) regex += '|'
                    regex += `${words[i]}`
                }
                regex += ')'
                let parts = text.split(new RegExp(`${regex}`, 'gi'));
                return (
                    <span>
                        {parts.map((part, i) =>
                            <span key={i}
                                className={words.includes(part.toLowerCase())
                                    ? "case-subtitle-highlighted" : "case-subtitle"}>
                                {part}
                            </span>
                        )}
                    </span>
                )
            }
            return (
                <div className="case-info-block">
                    <p className="case-title">{title}</p>
                    <p className="case-subtitle">
                        {getHighlightedText(subtitle, [])}
                    </p>
                </div>
            )
        }
        const { overall_assessment, aoclassification, surgical_approach, highlights, case_number, accident_time, age } = caseStudy

        return (
            <div>

                {/* <div class="row" style={{margin: "0px !important", padding: "0px !important"}}>
                    <div class="col-4">
                        {titleValueShow("CASE ID", case_number)}
                    </div>
                    <div class="col-6">
                        {titleValueShow("OVERALL ASSESSMENT", overall_assessment)}
                    </div>
                    <div class="col-3">
                        {titleValueShow("AGE", age )}
                    </div>
                    <div class="col-3">
                        {titleValueShow("AO", aoclassification)}
                    </div>
                    <div class="col-3">
                        {titleValueShow("TIME", accident_time)}
                    </div>
                </div> */}
                <div style={{display: "flex", margin: "0px !important", padding: "0px !important"}}>
                    <div style={{width: "28%"}}>
                        {titleValueShow("CASE ID", case_number)}
                    </div>
                    <div style={{width: "72%"}}>
                        {titleValueShow("OVERALL ASSESSMENT", overall_assessment)}
                    </div>

                </div>
                <div style={{display: "flex", margin: "0px !important", padding: "0px !important"}}>
                    <div style={{width: "28%"}}>
                        {titleValueShow("AGE", age + " years" )}
                    </div>
                    <div style={{width: "25%"}}>
                        {titleValueShow("AO", aoclassification)}
                    </div>
                    <div style={{width: "47%"}}>
                        {titleValueShow("TIME", accident_time)}
                    </div>
                </div>


                {masonClasification()}
                {booleanFields()}
                <div style={{marginTop: "10px"}}>
                    {titleValueShow("SURGICAL APPROACH", surgical_approach)}
                </div>
                <div style={{marginTop: "25px", marginBottom: "25px"}}>
                    {titleValueShow("SUMMARY", highlights)}
                </div>
                
            </div>
        )
    }

    const masonClasification = () => {
        const caseStudy = props.caseStudy
        let showMasonClasification = (caseStudy.masson_classification != null) && (caseStudy.masson_classification.length != 0)
        if (showMasonClasification)
            return (titleValueShow("Mason Classification", caseStudy.masson_classification))

        return null
    }

    const booleanFields = () => {
        const caseStudy = props.caseStudy

        let caseBooleanFields = [
            { field: "polytrauma", name: "Poly-trauma" },
            { field: "open", name: "Open" },
            { field: "dorsal_bone_defect", name: "Dorsal Bone Defect" },
            { field: "broken_syndemosis", name: "Broken Syndemosis" },
            { field: "broken_ulnar_styloid", name: "Broken Ulnar Styloid" },
            { field: "broken_greater_trochanter", name: "Broken Greater Trochanter" }
        ]

        return (
            caseBooleanFields.map((caseBooleanField) => {

                if (caseStudy[caseBooleanField.field])
                    return (
                        <div className="case-info-block" style={{margin: "10px 0px"}}><p>{caseBooleanField.name}</p></div>
                    )
            }))

    }

    const showCategories = () => {
        const caseStudy = props.caseStudy
        const categoryView = (name, score) => {
            return (
                <div className="category-container">
                    <p>{name}</p>
                    <div style={{ display: "flex" }}>
                        <div class={score >= 1 ? "circle-score " : "circle-score circle-score-empty"}></div>
                        <div class={score >= 1 ? "circle-score " : "circle-score circle-score-empty"}></div>
                        <div class={score >= 2 ? "circle-score " : "circle-score circle-score-empty"}></div>
                        <div class={score >= 2 ? "circle-score " : "circle-score circle-score-empty"}></div>
                        <div class={score >= 3 ? "circle-score " : "circle-score circle-score-empty"}></div>
                        <div class={score >= 3 ? "circle-score " : "circle-score circle-score-empty"}></div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div className="categories-container">
                    {categoryView("DISPLACEMENT", caseStudy.displacement)}
                    {categoryView("COMPLEXITY", caseStudy.complexity)}
                </div>
                <div className="categories-container">
                    {categoryView("REDUCTION", caseStudy.reduction)}
                    {categoryView("IMPLANT POSITION", caseStudy.implant_position)}
                </div>
            </div>
        )
    }

    return (
        <div className="details-container">
            {showInfo()}
            {showCategories()}
        </div>
    )
}

export default CaseDetails;