import React, { Component } from 'react';

class BottomDiv extends Component {

	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render() {
		return (
			<div style={{ position: "fixed", bottom: 0, backgroundColor: "white", width: "100%", margin: "0px", marginLeft: "-2px", zIndex: 1000, padding: 0, ...this.props.style}}>
				{this.props.children}
			</div>
		);
	}
}

export default BottomDiv;
