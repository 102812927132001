//Auth
export const USER_LOADING = "USER_LOADING"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"

//User
export const UPDATE_USER = 'UPDATE_USER'

//Errors
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

//Regions
export const GET_CASE_TYPES = 'GET_CASE_TYPES'
export const GET_CASE_STUDY = 'GET_CASE_STUDY'
export const GET_CHAPTER_BUTTON_SETS = 'GET_CHAPTER_BUTTON_SETS'
export const GET_SUB_REGION_CASES_BY_CASE_TYPE = 'GET_SUB_REGION_CASES_BY_CASE_TYPE'
export const GET_REFERENCE_CASES = 'GET_REFERENCE_CASES'
export const GET_EXPERT_OPINIONS = 'GET_EXPERT_OPINIONS'
export const GET_REFERENCE_CASE_BY_ID = 'GET_REFERENCE_CASE_BY_ID'
export const GET_EXPERT_OPINION_BY_ID = 'GET_EXPERT_OPINION_BY_ID'

export const SELECT_REGION = 'SELECT_REGION'
export const GET_3D_CASE_STUDY = 'GET_3D_CASE_STUDY'

export const SET_SELECTED_CASE_TYPE = 'SET_SELECTED_CASE_TYPE'
export const SET_SELECTED_INSIGHT = 'SET_SELECTED_INSIGHT'

//Search
export const GET_SEARCH_STUDY_CASES = 'GET_SEARCH_STUDY_CASES'
//Papers
export const GET_PAPERS = 'GET_PAPERS'
export const FILTER_PAPERS = 'FILTER_PAPERS'
export const SET_CATEGORY_SELECTED = 'SET_CATEGORY_SELECTED'

//Menu
export const OPEN_MENU = 'OPEN_MENU'
export const SELECT_MENU_DESKTOP = 'SELECT_MENU_DESKTOP'
export const SELECT_MENU_LEVEL_ONE = 'SELECT_MENU_LEVEL_ONE'
export const SELECT_MENU_LEVEL_TWO = 'SELECT_MENU_LEVEL_TWO'
export const SET_MENU_DARK_COLOR = 'SET_MENU_DARK_COLOR'