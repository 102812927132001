export const subRegions = {
    'Clavicle': 0,
    'ProximalHumerus': 1,
    'HumeralShaft': 2,
    'DistalHumerus': 3,
    'ProximalForearm': 4,
    'ForearmShaft': 5,
    'DistalRadius': 6,
    'Hand': 7,
    'ProximalFemur': 8,
    'FemoralShaft': 9,
    'DistalFemur': 10,
    'ProximalTibia': 11,
    'Patella': 12,
    'TibialShaft': 13,
    'Ankle': 14,
    'Foot': 15
};

export const subRegionsIdToName = {
    0 : "Clavicle/Scapula",
    1 : "Proximal Humerus",
    2 : "Humeral Shaft",
    3 : "Distal Humerus",
    4 : "Proximal Forearm",
    5 : "Forearm Shaft",
    6 : "Distal Radius",
    7 : "Hand",
    8 : "Proximal Femur",
    9 : "Femoral Shaft",
    10 : "Distal Femur",
    11 : "Proximal Tibia",
    12 : "Patella",
    13 : "Tibial Shaft",
    14 : "Ankle",
    15 : "Foot",
};

export const subRegionsNameToId = {
    "Clavicle/Scapula":  0,
    "Proximal Humerus":  1,
    "Humeral Shaft":  2,
    "Distal Humerus":  3,
    "Proximal Forearm":  4,
    "Forearm Shaft":  5,
    "Distal Radius":  6,
    "Hand":  7,
    "Proximal Femur":  8,
    "Femoral Shaft":  9,
    "Distal Femur": 10,
    "Proximal Tibia": 11,
    "Patella": 12,
    "Tibial Shaft": 13,
    "Ankle": 14,
    "Foot": 15,
};


export const limbRegionsOfSubRegions = {
    0 : "UPPER LIMB",
    1 : "UPPER LIMB",
    2 : "UPPER LIMB",
    3 : "UPPER LIMB",
    4 : "UPPER LIMB",
    5 : "UPPER LIMB",
    6 : "UPPER LIMB",
    7 : "UPPER LIMB",
    8 : "LOWER LIMB",
    9 : "LOWER LIMB",
    10 : "LOWER LIMB",
    11 : "LOWER LIMB",
    12 : "LOWER LIMB",
    13 : "LOWER LIMB",
    14 : "LOWER LIMB",
    15 : "LOWER LIMB",
}


export const menuOptionsId = {
    cases: 1,
    insights: 2,
    papers: 3,
    yourAccount: 4,
    register: 5,
    community: 6,
    about: 7,
    team: 8,
    login: 9,
    joinNow: 10,
    forgotPassword: 11,
}

export const papersCategories = [
    {
        name: "Biomechanics",
        id: 1
    },
    {
        name: "Cerclage",
        id: 2
    },
    {
        name: "Interactive 3D models",
        id: 3
    },
    {
        name: "ICUC",
        id: 4
    },
    {
        name: "Screws",
        id: 5
    },
    {
        name: "Plating",
        id: 6
    },
    {
        name: "Surgical technique",
        id: 7
    },
    {
        name: "Complications",
        id: 8
    }
]

export const ipadWidth = 768;
export const desktopWidth = 1300;