import {
    OPEN_MENU,
    SELECT_MENU_LEVEL_ONE,
    SELECT_MENU_LEVEL_TWO,
    SELECT_MENU_DESKTOP,
    SET_MENU_DARK_COLOR,
} from '../actions/types';

const initialState = {
    collapse: true,
    step: 1,
    casesMenuOpen: false,
    insightsMenuOpen: false,
    levelOne: {optionSelected: null, label: null},
    levelTwo: {label: null, link: null},
    menuOptionIdDesktop: null,
    openSubMenuDesktop: false,
    navBarDark: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OPEN_MENU:
            return {
                ...state,
                collapse: action.collapse,
                step: action.step,
                // casesMenuOpen: action.payload === 'cases',
                // insightsMenuOpen: action.payload === 'insights',
            };
        case SELECT_MENU_LEVEL_ONE:
            return {
                ...state,
                levelOne: action.levelOne,
            };
        case SELECT_MENU_LEVEL_TWO:
            return {
                ...state,
                levelTwo: action.levelTwo,
            };
        case SELECT_MENU_DESKTOP:
            return {
                ...state,
                openSubMenuDesktop: action.openSubMenu,
                menuOptionIdDesktop: action.menuOptionId,
            };
        case SET_MENU_DARK_COLOR:
            return {
                ...state,
                navBarDark: action.navBarDark
            };
        default:
            return state;
    }
}  