import React, { Component } from 'react'
import { NavLink, /*Route*/ } from 'react-router-dom'
import { logout } from 'actions/authActions';
import { openMenu, selectMenuLevelOne, selectMenuLevelTwo } from 'actions/menuActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import blueLogo from '../../../assets/blueLogo.png'
import whiteLogo from '../../../assets/whiteLogo.png'
import './navBar.css'
import RegionsMenu from './regionsMenu'
import PapersMenu from './papersMenu'
import { InputSearch } from '../../common'
import { MdSearch, MdKeyboardArrowLeft, MdArrowBack } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';

import { MdKeyboardArrowDown } from 'react-icons/md';

import { Separator } from 'components/common'

import {menuOptionsId, limbRegionsOfSubRegions, subRegionsIdToName} from 'utils/constants'

import iconoBuscar from 'assets/icons/iconoBuscar.svg'
import iconoFlechaIzquierdaAzul from 'assets/icons/iconoFlechaIzquierdaAzul.svg'

import $ from 'jquery'

var scrollPositionOutsideMenu = 0
var currentSelectMenuLevelOne
var currentSelectMenuLevelTwo

class NavBar extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            search: '',
            showSearch: false,
            barHeight: 0,
            showBarHeight: true,
            // menuOptionContainerHeight: "100vh"
        }
    }

    menuActions = () => {
        const links = [
            { name: "CASES", link: "/login", menuOptionId: menuOptionsId.cases },
            { name: "INSIGHTS", link: "/login", menuOptionId: menuOptionsId.insights },
            { name: "PAPERS", link: "/papers", menuOptionId: menuOptionsId.papers },
        ]

        if(!(process.env.REACT_APP_IS_STAGING === "true"))
            links.push({ name: "COMMUNITY", link: "/community", menuOptionId: null })

        const otherLinks = [];
        if(!(process.env.REACT_APP_IS_STAGING === "true"))
            otherLinks.push({ name: "ABOUT", link: "/about", menuOptionId: menuOptionsId.about, joinNowButton: false });

        otherLinks.push({ name: 'TEAM', link: '/team', menuOptionId: menuOptionsId.team, joinNowButton: false });


        const guestlinks = [
            { name: "Login", link: "/login", menuOptionId: null },
            { name: "Register", link: "/register", menuOptionId: menuOptionsId.register }
        ]
        const option = (aLink, clase) => {
            const { name, link,  menuOptionId} = aLink
            let {cases, insights, papers, yourAccount, register } = menuOptionsId

            let onClickSecondLevel = () => { this.props.openMenu(this.props.collapse,2); this.props.selectMenuLevelOne(menuOptionId); this.props.selectMenuLevelTwo(null,null); };
            
            return (
                <div key={name} className="menuOption" >
                    {menuOptionId === cases || menuOptionId === insights || menuOptionId === papers ?
                        <div style={{ cursor: "pointer" }} onClick={onClickSecondLevel}
                            className="menuOptionText">
                            <p className={clase}>{name}</p>
                        </div>
                        :
                        <NavLink to={link} className="menuOptionText">
                            <p onClick={() => { this.props.selectMenuLevelOne(null); this.props.selectMenuLevelTwo(null, null); this.onClickMobileMenu();  }} className={clase}>{name}</p>
                        </NavLink>
                    }
                </div>
            )
        }
        const guestLinks = () => {
            return (
                <div>
                    {guestlinks.map((link) => {
                        return option(link, "menuOptionOtherText")
                    })}
                </div>
            )
        }
        const authLinks = () => {
            return (
                <div>
                    {option({ name: 'Your Account', link: '/profile', menuOptionId: menuOptionsId.yourAccount }, "menuOptionOtherText")}
                    {this.logoutOption()}
                </div>
            )
        }
        let separationBetweenLines = 10
        return (
            <div>
                <div className="menuOptionContainer"></div>
                <div className="menu-option-sub-container">
                    {links.map((link) => {
                        return option(link)
                    })}
                    <Separator height={separationBetweenLines}/>
                    {this.separatorLine()}
                    <Separator height={separationBetweenLines}/>
                    {otherLinks.map((link) => {
                        return option(link)
                    })}
                    <Separator height={separationBetweenLines}/>
                    {this.separatorLine()}
                    <Separator height={separationBetweenLines}/>
                    {this.props.auth.isAuthenticated
                        ? authLinks()
                        : guestLinks()}
                </div>
            </div>
        )
    }
    navigateToRegion = (id) => {
        this.props.openMenu(true,1)
        const selectElement = (element) => document.querySelector(element)


        let hamburgerElement = selectElement('.hamburger')
        if(hamburgerElement)
            hamburgerElement.classList.toggle('active')

        selectElement('.icucBody').classList.toggle('active')

        const casesRoute = `/icuc-library/${id}`
        const insightsRoute = `/insights/${id}`
        let {levelOne} = this.props
        const isInsight = (levelOne.optionSelected === menuOptionsId.insights)
        let route = isInsight ? insightsRoute : casesRoute

        let nameBreadCrumbs = limbRegionsOfSubRegions[id] + "\u00a0" + " | " + "\u00a0" + subRegionsIdToName[id]
        this.props.selectMenuLevelTwo(nameBreadCrumbs, route)

        this.props.history.push(route)
    }

    navigateToPapers = (id, name) => {
        this.props.openMenu(true,1)
        const selectElement = (element) => document.querySelector(element)
        selectElement('.hamburger').classList.toggle('active')
        selectElement('.icucBody').classList.toggle('active')

        const papersRoute = `/papers/${id}`
        this.props.selectMenuLevelTwo(name, papersRoute)
        
        this.props.history.push(papersRoute)
    }
    loadMenuActions = () => {
        if (this.props.collapse) return null

        let {levelOne, levelTwo, step} = this.props

        let menuOptionSubContainerLeftMargin = 0

        if(levelOne.optionSelected!==null && levelTwo.label!==null && this.firstLevelBreadcrumbLabel && this.separatorBreadCrumb)
            menuOptionSubContainerLeftMargin = this.separatorBreadCrumb.getBoundingClientRect().right - this.firstLevelBreadcrumbLabel.getBoundingClientRect().left

        if (step === 2 && levelOne.optionSelected !== null) {
            
            if(levelOne.optionSelected === menuOptionsId.papers)
            if(levelOne.optionSelected === menuOptionsId.papers)
                return (
                    <PapersMenu menuOptionSubContainerLeftMargin={menuOptionSubContainerLeftMargin} marginLeftPaperCategory={0} desktopVersion={false} goBackToFirstMenu={() => this.props.selectMenuLevelOne(null)}
                        navigateToPapers={(id, name) => { this.navigateToPapers(id, name) }} />
                )

            return (
                <RegionsMenu menuOptionSubContainerLeftMargin={menuOptionSubContainerLeftMargin} marginLeftUpperLimb={0} marginLeftLowerLimb={-10}  desktopVersion={false} goBackToFirstMenu={() => this.props.selectMenuLevelOne(null)}
                    navigateToRegion={(id) => { this.navigateToRegion(id) }} />
            )
        }
        return this.menuActions()
    }

    logoutPage = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userJson');
        this.props.logout()
        this.props.history.push('')
        window.location.reload()
    }

    logoutOption = () => {
        return (
            <div className="menuOption" >
                <p className="menuOptionOtherText" style={{ cursor: "pointer", color: "red" }} onClick={() => { this.logoutPage() }}>Logout</p>
            </div>
        )
    }

    menuHamburgerClick = () => {

        if(this.state.showSearch)
            this.startSearch();

        this.onClickMobileMenu()
    }

    onClickMobileMenu = () => {

        this.saveCurrentDataBeforeOpeningMenu()

        const selectElement = (element) => document.querySelector(element)
        selectElement('.hamburger').classList.toggle('active')

        this.props.openMenu(!this.props.collapse,1)
        selectElement('.icucBody').classList.toggle('active')


    }

    saveCurrentDataBeforeOpeningMenu = () => {
        // alert(this.props.collapse)
        if(this.props.collapse) //save current state before open in case it closes the menu.
        {
            currentSelectMenuLevelOne = this.props.levelOne.optionSelected
            currentSelectMenuLevelTwo = {label: this.props.levelTwo.label, link: this.props.levelTwo.link}
            scrollPositionOutsideMenu = window.pageYOffset
        }
    }

    onClickBreadCrumbsLevelOne = (levelOne) => {


        if(levelOne === menuOptionsId.cases || levelOne === menuOptionsId.insights || levelOne === menuOptionsId.papers)
        {
            if(this.state.showSearch)
                this.startSearch();

            this.saveCurrentDataBeforeOpeningMenu()

            const selectElement = (element) => document.querySelector(element)
            let hamburgerElement = selectElement('.hamburger')
            if(hamburgerElement)
                hamburgerElement.classList.toggle('active')
    
            this.props.openMenu(!this.props.collapse,2)
            this.props.selectMenuLevelOne(levelOne)
            selectElement('.icucBody').classList.toggle('active')
        }

    }

    onClickLogo = () => {
        this.redirectLogicOfButtonOutsideMenu('')
    }

    onClickJoinNow = () => {
        this.redirectLogicOfButtonOutsideMenu('/register', menuOptionsId.joinNow)
    }

    redirectLogicOfButtonOutsideMenu = (link, menuLevelOne = null) => 
    {
        if (!this.props.collapse) {
            const selectElement = (element) => document.querySelector(element)
            selectElement('.icucBody').classList.toggle('active')
            selectElement('.hamburger').classList.toggle('active')
        }

        this.props.openMenu(true,1)
        this.props.selectMenuLevelOne(menuLevelOne)
        this.props.selectMenuLevelTwo(null,null)
        this.props.history.push(link)
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSearch = (e) => {

        if (e.key === 'Enter')
            this.searchLogic()

    }

    searchLogic = () => {
        
        
        const search = this.state.search
        if (search) {
            this.props.history.push('/search?searchInput=' + search)
            this.props.selectMenuLevelOne(null)
            this.props.selectMenuLevelTwo(null,null)
            // call search method and present results
        }
        this.startSearch()

    }

    startSearch = () => {
        const selectElement = (element) => document.querySelector(element)
        selectElement('.navbar-search-bar').classList.toggle('selected')
        if(!this.props.collapse)
            this.onClickMobileMenu()

        this.setState({ showSearch: !this.state.showSearch })

    }

    separatorLine = () => {
        
        return (
            <div style={{backgroundColor: "rgb(16,75,115)", width: "100%", height: "1px"}}>
            </div>
        )
    }

    componentDidMount() {
        this.updateBarHeight()
        scrollPositionOutsideMenu = 0
    }

    updateBarHeight = () => {
        let newNavBarHeight = this.topNavBarDiv.scrollHeight

        if(this.state.barHeight!==newNavBarHeight)
            this.setState({barHeight: newNavBarHeight})
    }
    
    componentDidUpdate(prevProps, prevState) {
        this.updateBarHeight()

        if(!this.props.collapse && prevProps.collapse)
            window.scrollTo(0, 0)

        if(this.props.location !== prevProps.location)
            scrollPositionOutsideMenu = 0

        if(this.props.collapse && !prevProps.collapse)
        {
            window.scrollTo(0, scrollPositionOutsideMenu)

            if(this.props.location === prevProps.location)
            {
                this.props.selectMenuLevelOne(currentSelectMenuLevelOne)
                if(currentSelectMenuLevelTwo)
                    this.props.selectMenuLevelTwo(currentSelectMenuLevelTwo.label,currentSelectMenuLevelTwo.link)
            }
            
        }

    }

    componentWillUnmount() {
        const selectElement = (element) => document.querySelector(element)
        if(selectElement('.icucBody').classList.contains('active'))
            selectElement('.icucBody').classList.toggle('active')
    }
    

    render() {
        
        let breadcrumbs = null
        let {levelOne, levelTwo} = this.props
        if(levelOne.optionSelected!==null)
        {
            breadcrumbs = <div className="breadcrumbsDiv" >
                    <span ref={el => (this.firstLevelBreadcrumbLabel = el)} onClick={() => {this.onClickBreadCrumbsLevelOne(this.props.levelOne.optionSelected)}} style={{cursor: "pointer"}}>
                        {levelOne.label} 
                        
                    </span>
                    {levelTwo.label!==null &&
                        <span>
                            
                            <span onClick={() => {this.props.history.push(levelTwo.link)}} style={{cursor: "pointer"}}>
                                <span ref={el => (this.separatorBreadCrumb = el)}>{" - "}</span>{levelTwo.label}  {" "}                 
                            </span>
                            <span onClick={() => {this.onClickBreadCrumbsLevelOne(levelOne.optionSelected)}} style={{cursor: "pointer"}}>
                                <MdKeyboardArrowDown />                 
                            </span>
                        </span>
                    }
            </div>
        }

        let iconSize = 40
        let iconSizeArrowBack = 30

        let changeNavBarDark = this.props.navBarDark && this.props.collapse && !this.state.showSearch

        let joinNowButtonColor = "rgb(0, 111, 127)"
        return (
            <div className="fixed-nav-bar" >
                <div className="topNavBar" ref={el => (this.topNavBarDiv = el)} style={{background: changeNavBarDark ? "rgb(0, 63, 106)" : "white"}}>
                    <div className="topNavBarContainer" style={{ borderBottom: changeNavBarDark ? "1px solid rgb(0,111,127)" : "1px solid #003f6a"}}>
                        {!this.state.showSearch ?
                            <div className="logo-with-info" style={{paddingLeft: "25px", display: "flex", alignItems: "center"}} onClick={this.onClickLogo}>
                                {process.env.REACT_APP_IS_STAGING === "true" ? (
                                    <span className="icuc-logo" style={{ color: changeNavBarDark ? "#fff" : "#003f6a", fontSize: "16px", marginLeft: "4px",letterSpacing:0, maxWidth:"140px", marginTop: "0px",fontFamily:"ProximaNovaLight, sans-serif", marginBottom: "0px !important"}}>Clinical cases repository</span>
                                ):(
                                    <>
                                        <img alt="" src={changeNavBarDark ? whiteLogo : blueLogo} className="logoNavBar"  />
                                        <span className="icuc-logo" style={{ color: changeNavBarDark ? "#fff" : "#003f6a", fontSize: "23px", marginLeft: "8px", marginTop: "0px", marginBottom: "0px !important"}}>ICUC</span>
                                    </>
                                )}
                         </div>
                            : null}
                        <div className="navbar-action-bar">
                            {!this.state.showSearch ?
                                <div style={{display: "flex", alignItems: "center"}}>

                                    {(!this.props.auth.isAuthenticated) &&
                                        <div style={{border: "1px solid " + joinNowButtonColor, marginRight: "10px", height: "32px", display: "flex", justifyContent: "center"}}>
                                            <NavLink to={"/register"} style={{fontSize: "14px", padding: "6px", fontFamily: "ProximaNovaRegular,sans-serif", color: joinNowButtonColor}}>
                                                <span onClick={() => {this.onClickJoinNow()}}>JOIN NOW</span>
                                            </NavLink>
                                        </div>   
                                    }
                                    <div className="navbar-search-bar" onClick={this.startSearch}>
                                        <img src={iconoBuscar} width={iconSize} height={iconSize} />
                                    </div>
                                </div>
                                : 
                                <div style={{display: "flex", margin: "15px 0px", alignItems: "center"}}>
                                    <div className="navbar-search-bar" style={{marginLeft: "5px", marginRight: "13px"}} onClick={this.startSearch}>
                                        <img src={iconoFlechaIzquierdaAzul} width={iconSizeArrowBack} height={iconSizeArrowBack} />
                                    </div> 
                                    {InputSearch({
                                        name: 'search',
                                        value: this.state.search,
                                        placeholder: 'Search for keywords in English',
                                        onChange: this.onChange,
                                        type: "search",
                                        border: false,
                                        onkeypress: (e) => { this.onSearch(e) }
                                        
                                    }, el => (this.inputSearchRef = el))}
                                </div>
                            }
                            <div style={{display: "flex", alignItems: "center", margin: this.state.showSearch ? "15px 0px" : "initial" }}>
                                <div className="hamburger" style={ this.state.showSearch ? {marginLeft: "23px"} : {display: "flex", alignItems: "center"}} onClick={() => { this.menuHamburgerClick() }}>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </div>
                            </div>


                        </div>
                    </div>
                    {breadcrumbs}
                </div>

                <div style={{height: this.state.barHeight + "px"}} id={"barHeightDiv"}>
                </div>

                {this.loadMenuActions()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    menu: state.menu,
    levelOne: state.menu.levelOne,
    levelTwo: state.menu.levelTwo,
    collapse: state.menu.collapse,
    step: state.menu.step,
    navBarDark: state.menu.navBarDark,
});

export default connect(
    mapStateToProps,
    { logout, selectMenuLevelOne, selectMenuLevelTwo, openMenu  }
)(withRouter(NavBar));