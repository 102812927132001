import { api, routes } from '../api'

import {
    OPEN_MENU,
    SELECT_MENU_LEVEL_ONE,
    SELECT_MENU_LEVEL_TWO,
    SELECT_MENU_DESKTOP,
    SET_MENU_DARK_COLOR,
} from './types';

import {menuOptionsId} from 'utils/constants'

export const openMenu = (collapse, step) => async (dispatch) => {
    try {
        dispatch(
            {
                type: OPEN_MENU,
                collapse,
                step
            }
        )
    } catch (err) {
        console.log(err)
    }
}

export const selectMenuLevelOne = (menuOptionId) => async (dispatch) => {
    
    try {

        let label = labelFromMenuOptionId(menuOptionId)

        dispatch(
            {
                type: SELECT_MENU_LEVEL_ONE,
                levelOne: {optionSelected: menuOptionId, label}
            }
        )
    } catch (err) {
        console.log(err)
    }
}

function labelFromMenuOptionId(menuOptionId) {
    let label = "";

    switch (menuOptionId) {
        case menuOptionsId.cases:
            label = "CASES"
            break;
        case menuOptionsId.insights:
            label = "INSIGHTS"
            break;
        case menuOptionsId.papers:
            label = "PAPERS"
            break;
        case menuOptionsId.yourAccount:
            label = "YOUR ACCOUNT"
            break;
        case menuOptionsId.joinNow:
        case menuOptionsId.register:
            label = "REGISTER"
            break;
        case menuOptionsId.community:
            label = "COMMUNITY"
            break;
        case menuOptionsId.forgotPassword:
            label = "FORGOT PASSWORD"
            break;

        default:
            label = null
            break;
    }

    return label;
}

export const selectMenuLevelTwo = (label, link) => async (dispatch) => {
    try {

        dispatch(
            {
                type: SELECT_MENU_LEVEL_TWO,
                levelTwo: {label, link}
            }
        )
    } catch (err) {
        console.log(err)
    }
}

export const selectMenuDesktop = (menuOptionId, openSubMenu) => async (dispatch) => {
    try {

        dispatch(
            {
                type: SELECT_MENU_DESKTOP,
                openSubMenu,
                menuOptionId
            }
        )
    } catch (err) {
        console.log(err)
    }
}

export const setMenuDarkColor = (navBarDark) => async (dispatch) => {
    try {

        dispatch(
            {
                type: SET_MENU_DARK_COLOR,
                navBarDark
            }
        )
    } catch (err) {
        console.log(err)
    }
}
