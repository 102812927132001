import React, { Component } from 'react';
import './App.css';
import Main from './main/main'

import { Provider } from 'react-redux'
import store from './store'
//import { loadUser, login } from './actions/authActions'

class App extends Component {
  async componentDidMount() {
    //store.dispatch(loadUser())
    //console.log("hola")
    //await store.dispatch(login({ email: 'mauri295@gmail.com', password: 'hola1234' }))
  }
  render() {
    return (
      <Provider store={store}>
        <div>
          <Main />
        </div>
      </Provider>
    );
  }
}

export default App;
