
import React from 'react'
import { FaFacebookSquare, FaYoutube, FaLinkedin, FaInstagram } from 'react-icons/fa';
//import { RedirectLink } from '../common'
import 'css/footer.css'

const Footer = () => {

    let sizeIcons = 28;
    const redes = [
        {
            icon: <FaFacebookSquare size={sizeIcons} />,
            link: 'https://www.facebook.com/icucmedicallearningapp',
        },
        {
            icon: <FaInstagram size={sizeIcons} />,
            link: 'https://www.instagram.com/icuc_app/',
        },
        {
            icon: <FaLinkedin size={sizeIcons} />,
            link: 'https://www.linkedin.com/company/icuc-medical-learning-app/about/',
        },
        {
            icon: <FaYoutube size={sizeIcons} />,
            link: 'https://www.youtube.com/channel/UCAsJdAV-uRDq-sbyo2C5yOg',
        }
    ]

    let paddingLeft = 50

    return (
        <footer className="footer" id={"footer"} style={{ backgroundColor: "rgb(77,77,77)", marginTop: "50px" }}>
            <div style={{width: "100%", height: "8px", backgroundColor: "rgb(174, 174, 174)"}}>
            </div>
            <div style={{  width: "100%", color: "#fff" }}>
                
                
                <div className="footerDataContainer">
                    {!(process.env.REACT_APP_IS_STAGING === "true") &&(
                        <div className="findUsOnContainer" >
                            <h4 className="findUsOnText" style={{paddingLeft: paddingLeft + "px" }} >
                                <span style={{fontWeight: "bold"}}>FIND US</span>
                                <span style={{fontWeight: "normal"}}> ON</span>
                            </h4>

                            <ul className="list-inline" style={{paddingLeft: paddingLeft + "px"}}>
                                {redes.map((item) => {
                                    return (
                                        <li className="list-inline-item mr-3" key={item.link}>
                                            <a href={item.link} style={{ color: "#fff" }}>
                                                {item.icon}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )}

                    <div className="termsPrivacyContainer" style={{paddingLeft: paddingLeft + "px" }}>
                        
                        <div>
                            <a className="termsPrivacyText" style={{color:'white'}} href="/terms">Terms of use</a>
                        </div>
                        <p className="termsPrivacySeparator">{'\u00A0'} /  {'\u00A0'} </p>
                        <div>
                            <a className="termsPrivacyText" style={{color:'white'}} href="/privacy-policy">Privacy Policy</a>
                        </div>
                        
                    </div>
                </div>

                <div style={{width: "100%", height: "1px", backgroundColor: "#aeaeae"}}>
                </div>
                <div style={{display: "flex", verticalAlign: "center", padding: "20px 0px"}}>
                    {process.env.REACT_APP_IS_STAGING === "true" ? (
                        <p style={{marginTop: "20px", color: "rgb(174, 174, 174)", paddingLeft: paddingLeft + "px"}}>
                            © Clinical Cases Repository 2020 All rights reserved
                        </p>
                    ):(
                        <p style={{marginTop: "20px", color: "rgb(174, 174, 174)", paddingLeft: paddingLeft + "px"}}>
                            © ICUC Cases Repository 2020 All rights reserved
                        </p>
                    )}
                </div>
            </div>

        </footer>
    )
}

export default Footer