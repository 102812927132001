import React, {Component} from 'react'
import '../input/input.css'
import './date_picker.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es);

class CustomDatePicker extends Component 
{

    componentDidMount() {
        if (this.datePicker && this.datePicker.input) {
            this.datePicker.input.readOnly = true;
        }
    }

    render() {
        const borderStyle = { border: this.props.border ? '1px solid black' : null }
        const styles = borderStyle
        return (
            <div className="customInputContainer"
                style={styles}>
                <div className="customInputInnerContainer">
                    <DatePicker
                        className="customDatePicker"
                        name="date_of_birth"
                        placeholderText="date of birth"
                        selected={this.props.value}
                        onChange={this.props.onChange}
                        // locale="es"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date("1800/01/01")}
                        maxDate={new Date("2019/01/01")}
                        // openToDate={new Date("1990/01/01")}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        ref={el => this.datePicker = el}
                    />
                </div>
            </div>
        )
    }
}

export default CustomDatePicker