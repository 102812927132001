import React from 'react'
import './TitleAuth.scss'

const TitleAuth = ({ title }) => {
    return (
        <div>
            <p className="title-auth-title">
                {title}
            </p>
            <div className="title-auth-line">
            </div>
        </div>
    )
}

export default TitleAuth