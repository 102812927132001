import React from 'react'
import './inputSearch.css'

const InputSearch = ({ name, placeholder, type, value, readonly, onkeypress = () => {},onChange = () => { }, error = false, border }, ref = null) => {
    let borderStyle = 'none'
    if(border) borderStyle = '1px solid black'
    const styles = { border: borderStyle } 
    return (
        <div className="input-search-container"
            style={styles}>
            <div className="input-search-inner-container">
                <input className="input-search-style"
                    onFocus={(e) => e.target.placeholder = ""}
                    onBlur={(e) => e.target.placeholder = placeholder}
                    autocomplete="off"
                    name={name}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    onChange={onChange}
                    error={error}
                    readOnly={readonly}
                    onKeyPress={(e) => onkeypress(e)}
                    ref={ref}
                    autoFocus={true}
                />
            </div>
        </div>
    )
}

export default InputSearch