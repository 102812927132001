import React, {Component} from 'react';
import $ from "jquery";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import './scroll_bar.css';

let scroll = (direction) => {
    let far = $('.centerScrollBar').width() / 2 * direction;
    let pos = $('.centerScrollBar').scrollLeft() + far;
    $('.centerScrollBar').animate({ scrollLeft: pos }, 250)
}

class ScrollBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showScrollButtons: false
        }
    }

    scrollToSelectedItem = () => {

        let scrollLenght = 0
        let scrollBarNodes = this.scrollItems.childNodes
        for (let i = 0; i < this.props.selectedItemPosition; i++)
            scrollLenght += scrollBarNodes[i].scrollWidth;    
        
        $('.centerScrollBar').animate({ scrollLeft: scrollLenght }, 200)
        
    }

    componentDidMount() {
        if(this.scrollItems.scrollWidth >= window.innerWidth)
        {
            this.setState({showScrollButtons: true})
            this.scrollToSelectedItem()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selectedItemPosition !== this.props.selectedItemPosition)
            this.scrollToSelectedItem()
    }
    

    render ()
    {
        let iconSizes = 32
        return (
            <div style={{overflow: "hidden"}}>
                {/* <div style={{ paddingTop: 5, paddingLeft: 10 }}>
                    <p style={{ fontSize: 18, fontWeight: '400', color: "black", marginBottom: "18px" }}>{this.props.type}</p>
                </div> */}
                <div style={{ position: "relative", display: "flex", width: "100vw", overflow: "hidden", marginTop: this.props.marginTop || "15px" }} >
                    { this.state.showScrollButtons &&
                        <div className="buttonContainer">
                            <button className="buttonScroll buttonScrollLeft" onClick={() => { scroll(-1) }} >
                            <FaAngleLeft size={iconSizes} /></button>
                        </div>
                    }
                    <div className="centerScrollBar" ref={el => (this.scrollItems = el)}>
                        {this.props.scrollBarItems}
                    </div>
                    { this.state.showScrollButtons &&
                        <div className="buttonContainer">
                            <button class="buttonScroll buttonScrollRight" onClick={() => { scroll(1) }}>
                                <FaAngleRight size={iconSizes} /></button>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

export default ScrollBar;
