import React from 'react'
import './Terms.css'

const Terms = () => {
    const terms = [
        {
            title: 'Terms of Use',
            description: 
            <p>
                Please carefully read those terms and conditions before using this site. This site is owned and operated by ICUC GmbH (ICUC). ICUC commits itself to respect the legal requirements regarding the privacy of the users from this site. <br></br> 
                By using this site, you acknowledge that you agree to be bound by the Terms and Conditions set out below and the Privacy Policy (together the Terms of Use). If you do not wish to be bound by the following Terms of Use, you must not access or use this site.
            </p>,
        },
        {
            title: '1. Disclaimer',
            description: 
            <p>
                This site is intended for use by health care professionals. We reserve the right to restrict access to the ICUC site at our sole discretion. In accessing the content of this site you represent and warrant that: a) you are a health care professional; and b) you are at least eighteen (18) years of age <br></br> 
                The procedures and products described on in this site are only to be applied in specially designed environments and by medical professionals specially certified and trained for such procedures. <br></br>
                The information provided in this site is for educational and informational purposes only and does not give professional advice or recommend particular products or procedures. If you need professional advice, you MUST seek a medical professional. The information contained in this site is not intended to present the only or necessarily the best products, procedures or therapies. <br></br>
                Whoever applies products or procedures shown or described on this site will do so at her/his own risk and is responsible for their compliance with all local laws, regulations and medical practice applicable. <br></br>
                All information contained in this site is only intended for preparing medical procedures and not for an aid whilst carrying out medical procedures.
            </p>
        },
        {
            title: '2. No Warranty',
            description:
            <p>
                While attempts will be made to keep this site current, information may become outdated. ICUC does not give any warranty or other assurance as to its content, accuracy, completeness, and compliance with local laws, regulations and medical practice or to its fitness for any particular purpose. All information contained in this site is provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind, expressed or implied. ICUC does not warrant that the site will meet your requirements, will be accurate, or will be uninterrupted or error free. ICUC affirmatively and expressly excludes and disclaims all express and implied warranties of merchantability and fitness for a particular purpose. ICUC shall not be responsible for any damage or loss of any kind arising out of or related to your use of the site, including without limitation data loss or corruption, regardless of whether such liability is based in tort, contract or otherwise. Under these Terms and Conditions, you assume all risk of errors and/or omissions in the site including the transmission or translation of information contained in it. The information should not be considered complete, nor should it be relied on to suggest a course of treatment for a particular individual. The site could also include technical or other inaccuracies or typographical errors. <br></br>
                Any statements about products described in this site are solely the view of ICUC and do not represent an endorsement or evaluation of or a warranty for such products. These statements may not be used in advertising or for any commercial purposes. <br></br>
                ICUC does not give any warranty or other assurance as to the operation, quality or functionality of this site. Access to the site may be interrupted, restricted or delayed for any reason. Moreover, ICUC does not give any warranty or other assurance as to the security and confidentiality of any internet-transmission to or from the site. Therefore, the site or any of the fax and mailing addresses should not be used to send confidential information.
            </p>
        },
        {
            title: '3. License Grant',
            description:
            <p>
                ICUC grants you a worldwide, non-exclusive, and non-transferable license to use the Site. You may download, view, copy, and print the information contained in the Site solely for your personal, non-commercial use. You agree not to transfer, share with or disseminate with anyone and for any purpose information contained in this Site unless it is in accordance with the Term of Use including facilitate unfair competition with the ICUC Site. It is completely banned under this license grant to: sell, resell, rent, reproduce, adapt, modify, translate, create derivative works, distribute, transfer, stream, broadcast (with any purpose) any of the information contained in the Site.
            </p>
        },
        {
            title: '4. Links to Other Sites',
            description:
            <p>
                This Site may contain links to other websites. ICUC is not responsible for and has no control over the content, accuracy or opinions expressed in such websites. ICUC does encourage users of this Site to carefully read the privacy policy of every website they visit.<br></br>
                By accepting the "Terms of Use" you agree not to link, upload, post or transmit any illegal, obscene, offensive or otherwise inappropriate material to the ICUC Site. In addition, you agree not to use any of ICUC proprietary logos, marks, or other distinctive graphics, video, or audio material in your links, without the express and prior written permission, which ICUC may withhold in its sole discretion. Furthermore, you agree not to link in any manner reasonably likely to suggest affiliation with or endorsement or sponsorship by ICUC.
            </p>
        },
        {
            title: '5. No Liability',
            description:
            <p>
                To the fullest extent permitted by law, ICUC GmbH nor its affiliates, officers, directors, employees or agents shall not be liable for any damages or losses (including, without limitation, personal injury, financial loss, damages for loss in business projects, loss of profits or other consequential losses) arising from the use of or inability to use this site or any material or information appearing on this site, or from any action or decision taken as a result of using this site or any such material or information.
            </p>
        },
        {
            title: '6. Intellectual Property Rights',
            description:
            <p>
                The Site contains valuable information and it is of exclusive property of ICUC or its licensors and nothing in these Terms and Conditions shall be construed as transferring or assigning any such ownership rights to you or any other person or entity.<br></br>
                The content of this site is owned and controlled by ICUC and is protected by worldwide copyrights, property, and trademark laws. Portions of this content may be downloaded and copied for personal, non-commercial use only. The modification, redistribution, commercially exploit or publication of any part of this site is prohibited.<br></br>
                Some trademarks, registered trademarks or service marks, product names, company names and logos are protected by intellectual property rights, even though specific reference to this fact is not always made on this site.<br></br>
                Reference to any products, services, processes or other information, by trade name, trademark, service mark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by ICUC.
            </p>
        },
        {
            title: '7. Update to the Terms of Use',
            description:
            <p>
                ICUC reserves the right to change or modify these terms of use at any time without prior notification, and you agree to give effect to such changes or modifications upon their being posted to this site. Users are recommended to check back periodically, and especially before providing any personally identifiable information. This document was last updated in December 2013.
            </p>
        },
        {
            title: '8. Infringement and indemnification',
            description:
            <p>
                If you do not comply with these terms and conditions, ICUC has the right to suspend or eliminate your account and ICUC reserves the right to any legal action we think is appropriate.<br></br>
                If, based on an agreement between you and ICUC, you are entitled to access this site to third persons, you represent that all such third persons using this site are contractually bound by these terms and conditions as well.<br></br>
                You agree to defend, indemnify and hold ICUC harmless from any and all liabilities, costs and expenses, including reasonable attorney's fees, related to any violation of these terms and conditions.
            </p>
        },
        {
            title: '9. General',
            description:
            <p>
                By using the site you acknowledge and irrevocably agrees that the courts of Switzerland shall have exclusive jurisdiction to hear and decide any suit, action or proceedings, and/or to settle any disputes, which may arise out of or in connection with these Terms of Use or its formation or validity and, for these purposes, each party irrevocably submits to the jurisdiction of the courts of Switzerland.
            </p>
        },
        {
            title: '10. Contact',
            description: 
            <p>
               If you have any questions, comments, or concerns about this Terms of Use, please contact us at: <a href="mailto:support@icuc.net">support@icuc.net</a>
            </p>
        },
    ]
    return (
        <div className="terms-container">
            {terms.map((term) => {
                return (
                    <div>
                        <p className="terms-title">{term.title}</p>
                        <p className="terms-description">{term.description}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default Terms