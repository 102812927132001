import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class RedirectLink extends Component {

    redirect = (path, e) => {
        e.preventDefault()
        this.props.history.push(path)
    }

    render() {
        return (
            <a href="#" style={this.props.hasStyle ? { color: 'white' } : null} onClick={(e) => this.redirect(this.props.redirect, e)} >{this.props.name}</a>
        )
    }
}

export default withRouter(RedirectLink)