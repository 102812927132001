import { api, routes } from '../api'
import { returnErrors } from './errorActions'

import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
} from './types';
import { af } from 'date-fns/esm/locale';

// Login user 
export const login = (user) => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    let route = routes.auth
    return async (dispatch) => {
        try {
            const res = await api.post(route, user, config)

            addUserInfoToLocalStorage(res.data)

            console.log(res.data.token)
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            })

        } catch (err) {

            if(err.response!==undefined)
            {
                dispatch(
                    returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL')
                )
            }
            else
            {
                dispatch(
                    returnErrors({kind_message: "server response error"}, 500, 'LOGIN_FAIL')
                )
            }

        }
    }
}

function addUserInfoToLocalStorage(data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('userJson', JSON.stringify(data.user));
}

// Register User
export const register = (user, complete = () => { }) => async dispatch => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    let route = routes.users
    try {
        if(typeof user.user.date_of_birth==="object")
            user.user.date_of_birth = user.user.date_of_birth.toISOString().slice(0,10)
        
        const res = await api.post(route, user, config)

        addUserInfoToLocalStorage(res.data)
        
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        })

        if (res.status === 200) 
            complete(true)

    } catch (err) {
        dispatch(
            returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL')
        );
        dispatch({
            type: REGISTER_FAIL
        })
    }
}

// Logout User
export const logout = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

// Setup config/headers and token
export const tokenConfig = getState => {
    // Get token from localstorage
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    // If token, add to headers
    if (token) {
        config.headers['Authorization'] = token;
    }

    return config;
}