import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { NavBar, NavBarDesktop } from '../components/menu'
// import { Login, Register, ForgotPassword, ThankYouPage } from '../components/auth'
// import { Terms, PrivacyPolicy, Footer } from '../components/menu'
import { Footer } from '../components/menu'
// import {CaseStudy, IcucLibrary, ReferenceCase, ExpertOpinion} from '../components/regions'
// import { Insights } from '../components/insights'
// import { Home, About, Team, TeamMemberView, Protocol } from 'components/landing'
// import { Profile } from '../components/profile'
// import { Community } from '../components/community'
// import { Papers } from '../components/papers'

import PrivateRoute from 'utils/privateRoute'
// import Search from 'components/search/search'

import {isDesktop} from 'utils/generalFunctions'

import '../css/main.css'
  
import WindowSizeListener from 'react-window-size-listener'

const Login = lazy(() => import('../components/auth/login/login'));
const Register = lazy(() => import('../components/auth/register/register'));
const ForgotPassword = lazy(() => import('../components/auth/forgot-password/forgotPassword'));
const ThankYouPage = lazy(() => import('../components/auth/thank-you/thankYouPage'));
const Terms = lazy(() => import('../components/menu/Terms/Terms'));
const PrivacyPolicy = lazy(() => import('../components/menu/Terms/PrivacyPolicy'));
const CaseStudy = lazy(() => import('../components/regions/caseStudy'));
const IcucLibrary = lazy(() => import('../components/regions/icucLibrary'));
const ReferenceCase = lazy(() => import('../components/regions/referenceCase'));
const ExpertOpinion = lazy(() => import('../components/regions/expertOpinion'));
const Insights = lazy(() => import('../components/insights/Insights'));
const Home = lazy(() => import ('components/landing/home/home'));
const About = lazy(() => import('components/landing/aboutUs/aboutUs'));
const Team = lazy(() => import('components/landing/team/Team'));
const TeamMemberView = lazy(() => import('components/landing/team/teamMemberView'));
const Protocol = lazy(() => import('components/landing/aboutUs/protocol'));
const Profile = lazy(() => import('../components/profile/profile'));
const Community = lazy(() => import('../components/community/community'));
const Papers = lazy(() => import('../components/papers/Papers'));
const Search = lazy(() => import('components/search/search'));

class Main extends Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
            windowWidth:0,
        }
    }

    redirectToQuizzes = () => {

        let redirectsQuizzes = [
            "/insightsDistalRadius",
            "/insightsPatella",
            "/insightsProximalHumerus",
            "/quizz0",
            "/quizz1",
            "/quizz1ProxHum",
            "/quizz2",
            "/quizz3",
            "/quizz4",
            "/quizz5",
            "/quizz6",
            "/quizz7",
            "/quizzfemur1",
            "/quizzfemur2",
            "/quizzfemur3",
        ]

        let currentPath =  window.location.pathname
        if(redirectsQuizzes.indexOf(currentPath) > -1)
            window.location.href = "http://www.siniscalco.com.uy/tincho" + currentPath;
    
    }

    redirectGoogleSubPagesLinks = () => {

        let redirectGoogleSubPagesLinks = ["/multimedia/", "/faq/"]

        let currentPath =  window.location.pathname
        if(redirectGoogleSubPagesLinks.indexOf(currentPath) > -1)
            window.location.href = "https://icuc.net";
    
    }

    componentDidMount() {
        this.redirectToQuizzes()
        this.redirectGoogleSubPagesLinks()
    }

    render() {
        return (
            <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}>
                    <div>
                        <WindowSizeListener onResize={windowSize => {
                            
                            this.setState({windowWidth: windowSize.windowWidth })

                        }}/>
                        {isDesktop(this.state.windowWidth) ? <NavBarDesktop /> : <NavBar />}  
                        
                        <div className="icucBody">
                            <Switch>

                                {/* AUTH */}
                                <Route path="/login" component={Login} />
                                <Route path="/register" component={Register} />
                                <Route path="/thank-you" component={ThankYouPage} />
                                <Route path="/forgot" component={ForgotPassword} />

                                {/* MENU */}
                                {!(process.env.REACT_APP_IS_STAGING === "true") &&
                                    <Route path="/about" component={About} />
                                }
                                <Route path="/protocol" component={Protocol} />
                                <Route path="/terms" component={Terms} />
                                <Route path="/privacy-policy" component={PrivacyPolicy} />
                                <Route path="/team" component={Team} exact />
                                <Route path="/team-member/:id" component={TeamMemberView} exact />

                                {/* REGIONS */}
                                <Route path="/icuc-library/:id" component={IcucLibrary} />
                                <PrivateRoute path="/case-study/:caseStudyId" component={CaseStudy} />
                                <PrivateRoute path="/reference-case/:referenceCaseId" component={ReferenceCase} />
                                <PrivateRoute path="/expert-opinion/:expertOpinionId" component={ExpertOpinion} />

                                { /* INSIGHTS */ }
                                <Route path="/insights/:id" component={Insights} />
                                
                                { /* INSIGHTS */ }
                                <Route path="/search" component={Search} />

                                { /* PROFILE */}
                                <Route path="/profile" component={Profile} />

                                { /* PAPERS */}
                                <Route path="/papers/:papersCategoryId" component={Papers} />

                                { /* COMMUNITY */}
                                {!(process.env.REACT_APP_IS_STAGING === "true") &&
                                    <Route path="/community" component={Community} />
                                }

                                { /* Landing */}
                                <Route path="/" component={Home} exact />

                            </Switch>
                            <Footer />
                        </div>
                    </div>
                </Suspense>
            </BrowserRouter>
        );
    }
}


export default Main