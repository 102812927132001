import React, { Component } from 'react'
import { NavLink, /*Route*/ } from 'react-router-dom'
import { logout } from 'actions/authActions';
import { openMenu, selectMenuLevelOne, selectMenuLevelTwo, selectMenuDesktop } from 'actions/menuActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import blueLogo from '../../../assets/blueLogo.png'
import whiteLogo from '../../../assets/whiteLogo.png'
import './navBar.css'
import RegionsMenu from './regionsMenu'
import PapersMenu from './papersMenu'
import { InputSearch } from '../../common'
import { MdSearch, MdKeyboardArrowLeft, MdArrowBack } from 'react-icons/md';
import { GoX } from 'react-icons/go';

import { MdKeyboardArrowDown } from 'react-icons/md';

import { Separator } from 'components/common'

import {menuOptionsId, limbRegionsOfSubRegions, subRegionsIdToName} from 'utils/constants'

import iconoBuscar from 'assets/icons/iconoBuscar.svg'
import iconoCerrarBuscar from 'assets/icons/iconoCerrarBuscar.svg'

var currentMenuOptionId = null

var heightMenuOptionActive = 0
var heightMenuHamburgerActive = 0
var heightMenu = 0

var topPositionMenuOptionActive = 0

var activeMenuOptionDifferenceFromBottom = 4

class NavBarDesktop extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            search: '',
            barHeight: 0,
            showSearch: false,
            // menuOptionContainerHeight: "100vh"
        }
    }

    shouldOpenMenuOptionsId = (menuOptionId) => {
        return menuOptionId === menuOptionsId.cases || menuOptionId === menuOptionsId.insights  || menuOptionId === menuOptionsId.papers 
    }

    isMainMenuActions = (menuOptionId) => {
        let mainMenuActions = [menuOptionsId.cases, menuOptionsId.insights, menuOptionsId.papers, menuOptionsId.community]

        return mainMenuActions.includes(menuOptionId)
    }

    menuActions = () => {
        let links = [
            { name: "CASES", link: "/login", menuOptionId: menuOptionsId.cases, joinNowButton: false },
            { name: "INSIGHTS", link: "/login", menuOptionId: menuOptionsId.insights, joinNowButton: false },
            { name: "PAPERS", link: "/papers", menuOptionId: menuOptionsId.papers, joinNowButton: false },

        ]
        if(!(process.env.REACT_APP_IS_STAGING === "true"))
            links.push({ name: "COMMUNITY", link: "/community", menuOptionId: null })

        if(!this.props.auth.isAuthenticated)
            links.push({ name: "JOIN NOW", link: "/register", menuOptionId: menuOptionsId.joinNow, joinNowButton: true })

        const otherLinks = [];
        if(!(process.env.REACT_APP_IS_STAGING === "true"))
            otherLinks.push({ name: "ABOUT", link: "/about", menuOptionId: menuOptionsId.about, joinNowButton: false });

        otherLinks.push({ name: 'TEAM', link: '/team', menuOptionId: menuOptionsId.team, joinNowButton: false });


        const guestlinks = [
            { name: "Login", link: "/login", menuOptionId: menuOptionsId.login, joinNowButton: false },
            { name: "Register", link: "/register", menuOptionId: menuOptionsId.register, joinNowButton: false }
        ]


        const option = (aLink, clase) => {
            const { name, link,  menuOptionId} = aLink
            let {cases, insights, papers, yourAccount, register } = menuOptionsId

            let onClickSecondLevel = (menuOptionId) => { 
                let newState = {menuOptionId: menuOptionId, openSubMenu: true}

                

                if(this.props.menuOptionId===menuOptionId && this.props.openSubMenu)
                {
                    newState = {menuOptionId: currentMenuOptionId, openSubMenu: false}
                    currentMenuOptionId = null
                }

                if(currentMenuOptionId===null)
                    currentMenuOptionId = this.props.menuOptionId

                this.props.selectMenuDesktop(newState.menuOptionId, newState.openSubMenu)
            
            };
            
            

            let colorMenuOption = this.props.navBarDark? "white" : "rgb(0,63,106)"

            return (

                <div key={name} className="menuOption" style={aLink.joinNowButton ? {height: "30px", position: "relative", border:  "1px solid " + colorMenuOption, padding: "7px", display: "flex", alignItems: "center"} : {position: "relative"}} >
                    
                    
                    
                    {menuOptionId === cases || menuOptionId === insights || menuOptionId === papers ?
                        <div  onClick={() => onClickSecondLevel(menuOptionId)}
                            className="menuOptionText" style={{ cursor: "pointer", color: colorMenuOption }}>
                            <p className={clase}>{name}</p>
                        </div>
                        :
                        <NavLink to={link} className="menuOptionText" style={{textDecoration: "none" }}>
                            <p onClick={() => { if(menuOptionId!==menuOptionsId.community) {this.props.selectMenuLevelOne(null); } this.props.selectMenuLevelTwo(null,null);  this.props.selectMenuDesktop(menuOptionId, false) }} className={clase} style={{color: colorMenuOption}}>{name}</p>
                        </NavLink>
                    }
                    {this.props.openSubMenu && this.shouldOpenMenuOptionsId(menuOptionId) &&  this.props.menuOptionId==menuOptionId &&
                    
                        <div style={{position: "absolute", zIndex: 1000,  height:  menuOptionId===menuOptionsId.papers ? "360px" : "370px", marginTop: "0px",  width: menuOptionId===menuOptionsId.papers ? "250px" :"365px", backgroundColor: "white", top: heightMenuOptionActive + "px", left: "0%", border: "1px solid rgb(0,111,127)", paddingTop: "5px" }}>
                                
                                <div>

                                    {((menuOptionId===menuOptionsId.cases) || (menuOptionId===menuOptionsId.insights)) ?
                                        <RegionsMenu menuOptionSubContainerLeftMargin={0}  marginLeftUpperLimb={30} marginLeftLowerLimb={20}  desktopVersion={true}
                                        navigateToRegion={(id) => { this.navigateToRegion(id) }} />: null}

                                    {(menuOptionId===menuOptionsId.papers) ?  
                                        <PapersMenu menuOptionSubContainerLeftMargin={0} marginLeftPaperCategory={24} desktopVersion={true}
                                        navigateToPapers={(id, name) => { this.navigateToPapers(id, name) }} /> : null}

                                </div>
                                
                        </div>
                    }
                    {
                        (this.props.menuOptionId==menuOptionId) && this.props.menuOptionId!=menuOptionsId.joinNow &&
                        <div className="menuActiveIndicatorLine" style={{top: (heightMenuOptionActive - activeMenuOptionDifferenceFromBottom) + "px" }}> </div>
                    }

                </div>
            )
        }

        let totalPercentage = 100 
        let collapsePercentageLeftWidthWhenAuth = 42
        let collapsePercentageLeftWidthWhenNotAuth = 50

        let collapsePercentageLeftWidth = this.props.auth.isAuthenticated ? 42 : 50

        const guestLinks = () => {
            return (
                <div className="collapseMenuHalfDesktop collapseMenuHalfDesktopRight" style={{width: (totalPercentage - collapsePercentageLeftWidth) + "%"}}>
                    {guestlinks.map((link) => {
                        return option(link, "menuOptionText")
                    })}
                </div>
            )
        }
        const authLinks = () => {
            return (
                <div className="collapseMenuHalfDesktop collapseMenuHalfDesktopRight" style={{width: (totalPercentage - collapsePercentageLeftWidth) + "%"}}>
                    {option({ name: 'Your Account', link: '/profile', menuOptionId: menuOptionsId.yourAccount }, "menuOptionText")}
                    {this.logoutOption()}
                </div>
            )
        }
        let separationBetweenLines = 10

        return (
            <div ref={el => (this.navBarItemsContainer = el)} style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: this.props.collapse && !this.props.auth.isAuthenticated ? "440px" : "320px", position: "relative"}}>
                    {this.props.collapse ? links.map((link) => {

                        return option(link)
                    }) : null}

                    {!this.props.collapse ? <div className="collapseMenuHalfDesktop collapseMenuHalfDesktopLeft" style={{width: collapsePercentageLeftWidth + "%"}}>{otherLinks.map((link) => {
                        return option(link)
                    })}</div> : null}

                    {!this.props.collapse ? this.separatorLine(collapsePercentageLeftWidth) : null }

                    {this.props.auth.isAuthenticated && !this.props.collapse
                        ? authLinks() : null}

                    {!this.props.auth.isAuthenticated && !this.props.collapse
                        ? guestLinks() : null}
            </div>
        )
    }
    navigateToRegion = (id) => {
        
        this.props.openMenu(true,1)

        const casesRoute = `/icuc-library/${id}`
        const insightsRoute = `/insights/${id}`
        let {levelOne} = this.props
        const isInsight = (this.props.menuOptionId === menuOptionsId.insights)
        let route = isInsight ? insightsRoute : casesRoute

        let nameBreadCrumbs = limbRegionsOfSubRegions[id] + "\u00a0" + " | " + "\u00a0" + subRegionsIdToName[id]
        
        this.props.selectMenuLevelOne(this.props.menuOptionId)
        this.props.selectMenuLevelTwo(nameBreadCrumbs, route)

        this.props.selectMenuDesktop(this.props.menuOptionId, false)

        this.props.history.push(route)
    }

    navigateToPapers = (id, name) => {
        this.props.openMenu(true,1)

        const papersRoute = `/papers/${id}`
        this.props.selectMenuLevelOne(this.props.menuOptionId)
        this.props.selectMenuLevelTwo(name, papersRoute)

        this.props.selectMenuDesktop(this.props.menuOptionId, false)

        this.props.history.push(papersRoute)
    }

    logoutPage = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userJson');
        this.props.logout()
        this.props.history.push('')
        window.location.reload()
    }

    logoutOption = () => {
        return (
            <div className="menuOption" >
                <p className="menuOptionOtherText" style={{ cursor: "pointer", color: "red" }} onClick={() => { this.logoutPage() }}>Logout</p>
            </div>
        )
    }

    onClickMobileMenu = () => {

        const selectElement = (element) => document.querySelector(element)
        selectElement('.hamburger').classList.toggle('active')

        this.props.openMenu(!this.props.collapse,1)

    }

    onClickBreadCrumbsLevelOne = (levelOne) => {

        const selectElement = (element) => document.querySelector(element)
        let hamburgerElement = selectElement('.hamburger')
        if(hamburgerElement)
            hamburgerElement.classList.toggle('active')

        this.props.openMenu(!this.props.collapse,2)
        this.props.selectMenuLevelOne(levelOne)

    }

    onClickLogo = () => {
        if (!this.props.collapse) {
            const selectElement = (element) => document.querySelector(element)
            selectElement('.hamburger').classList.toggle('active')
        }

        this.props.selectMenuDesktop(null, false)
        this.props.openMenu(true,1)
        this.props.selectMenuLevelOne(null)
        this.props.selectMenuLevelTwo(null,null)
        this.props.history.push('')
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSearch = (e) => {

        if (e.key === 'Enter')
            this.searchLogic()

    }

    searchLogic = () => {
        
        const search = this.state.search
        if (search) {
            this.props.history.push('/search?searchInput=' + search)
            this.props.selectMenuDesktop(null, false)
            this.props.selectMenuLevelOne(null)
            this.props.selectMenuLevelTwo(null,null)
        }

        this.startSearch()

    }

    startSearch = () => {
        const selectElement = (element) => document.querySelector(element)
        // selectElement('.navbar-search-bar').classList.toggle('selected')
        if(!this.props.collapse)
            this.onClickMobileMenu()

        setTimeout(() => { this.inputSearchRef.focus() }, 300);   
            
        this.setState({showSearch: !this.state.showSearch})
        this.cancelSearchInput.classList.toggle('show')
        this.searchInputContainer.classList.toggle('show')

    }

    separatorLine = (leftPosition) => {
        // alert(barHeight)
        return (
            <div style={{backgroundColor: "rgb(175,175,175)", width: "1px", height: heightMenu + "px", position: "absolute", top: "calc(0% - " + topPositionMenuOptionActive + "px)", left: leftPosition + "%"}}></div>
        )
    }

    calculateHeightMenuOptionActive = () => {
        let menuOptionDivElementBoundingRect = this.navBarItemsContainer.children[0].getBoundingClientRect()
        let differenceNavBarItemsTopNavBar = this.topNavBarContainer.getBoundingClientRect().bottom - menuOptionDivElementBoundingRect.bottom
        let heightMenuOptionItemDiv = menuOptionDivElementBoundingRect.bottom - menuOptionDivElementBoundingRect.top
        // alert(this.topNavBarContainer.getBoundingClientRect().bottom)
        return  heightMenuOptionItemDiv + differenceNavBarItemsTopNavBar

        
    }

    calculateHeightMenuHamburgerActive = () => {
        let menuHamburgerBoundingRect = this.menuHamburger.getBoundingClientRect()
        let differenceMenuHamburgerTopNavBar = this.topNavBarContainer.getBoundingClientRect().bottom - menuHamburgerBoundingRect.bottom
        let heightDivMenuHamburger = menuHamburgerBoundingRect.bottom - menuHamburgerBoundingRect.top
        
        return heightDivMenuHamburger + differenceMenuHamburgerTopNavBar
    }

    componentDidMount() {
        this.updateBarHeight()

        heightMenuOptionActive =  this.calculateHeightMenuOptionActive()        
        heightMenuHamburgerActive =  this.calculateHeightMenuHamburgerActive()        
    
        heightMenu = this.topNavBarContainer.getBoundingClientRect().bottom

        topPositionMenuOptionActive = this.navBarItemsContainer.getBoundingClientRect().top

        currentMenuOptionId = null
    }

    updateBarHeight = () => {
        let newNavBarHeight = this.topNavBarDiv.scrollHeight

        if(this.state.barHeight!==newNavBarHeight)
            this.setState({barHeight: newNavBarHeight})
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(!this.props.openSubMenu)
            this.updateBarHeight()

    }

    levelTwoLabel = (levelTwoLabel) => {

        let limbText = ""

        if(levelTwoLabel.includes("UPPER LIMB"))
            limbText = "UPPER LIMB"

        if(levelTwoLabel.includes("LOWER LIMB"))
            limbText = "LOWER LIMB"

        let textWithoutLimbText = levelTwoLabel.replace(limbText, "")

        return (
            <span>
                <span style={{fontWeight: "bold"}}>{limbText}</span>{textWithoutLimbText}
            </span>
        );
    }

    componentWillUnmount() {
        this.props.openMenu(true, 1)
    }

    render() {
        
        let breadcrumbs = null
        let {levelOne, levelTwo} = this.props
        let levelTwoLabel
        if(levelOne.optionSelected!==null)
        {


            if(levelTwo.label!==null)
                levelTwoLabel = this.levelTwoLabel(levelTwo.label) 

            
            breadcrumbs = 
            <div className="breadcrumbsContainer" >
                <div className="breadcrumbsDiv" >
                    
                    <span>
                        {levelOne.label}{"\u00a0"}
                        
                    </span>
                    {levelTwo.label!==null &&
                        <span>
                            <span>
                                {"- "}{levelTwoLabel}  {" "}                 
                            </span>
                        </span>
                    }
                </div>
            </div>

        }

        let iconSize = 40
        let iconSizeCloseSearch = 40
        return (
            <div className="fixed-nav-bar-desktop" >
                <div className="topNavBar" ref={el => (this.topNavBarDiv = el)}  style={{zIndex: "105", background: this.props.navBarDark ? "linear-gradient(-45deg, rgb(6, 31, 70), rgb(22, 63, 103))" : "white"}}>
                    <div className="topNavBarContainer" ref={el => (this.topNavBarContainer = el)} style={{justifyContent: "initial"}}>
                        <div className="logo-with-info" style={{paddingLeft: "50px", width: "30%"}} onClick={this.onClickLogo}>
                            {process.env.REACT_APP_IS_STAGING === "true" ? (
                                <p className="icuc-logo" style={{ color: this.props.navBarDark ? "#fff" : "#003fa6",fontFamily:"ProximaNovaLight, sans-serif"}}>Clinical cases repository</p>
                            ):(
                                <>
                                    <img alt="" src={this.props.navBarDark? whiteLogo : blueLogo} className="logoNavBar"  />
                                    <p className="icuc-logo" style={{ color: this.props.navBarDark ? "#fff" : "#003fa6"}}>ICUC</p>
                                </>
                                )}
                        </div>
                        <div className="navbar-action-bar" style={{width: "70%", justifyContent: "flex-end"}}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>


                                <div ref={el => this.searchInputContainer = el} className="searchInputDesktopContainer" > 
                                    {InputSearch({
                                        name: 'search',
                                        value: this.state.search,
                                        placeholder: 'Search for keywords in English',
                                        onChange: this.onChange,
                                        type: "search",
                                        border: false,
                                        onkeypress: (e) => { this.onSearch(e) },
                                        setRef: el => (this.searchInput = el),
                                    }, el => (this.inputSearchRef = el))}
                                  
                                    <span className="cancelSearchInput" style={{transition: this.state.showSearch ? "all 0.3s ease-out 0s" : "all 0.5s ease-out 0.2s" }} onClick={this.startSearch}  ref={el => (this.cancelSearchInput = el)}>
                                        <img src={iconoCerrarBuscar} width={iconSizeCloseSearch} height={iconSizeCloseSearch} />
                                    </span>                                    


                                </div>

                                { !this.state.showSearch &&
                                    <span className="navbar-search-bar" onClick={this.startSearch} style={{marginRight: "30px", cursor: "pointer"}}>
                                        <img src={iconoBuscar} width={iconSize} height={iconSize} />
                                    </span> 
                                }



                                {this.menuActions()}
                                <div className="hamburger" style={{marginLeft: "32px", marginRight: "65px", position: "relative" }} onClick={() => { this.onClickMobileMenu() }} ref={el => (this.menuHamburger = el)}>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>

                                    {
                                        !this.isMainMenuActions(this.props.menuOptionId) && this.props.menuOptionId!==null && this.props.collapse && this.props.menuOptionId!=menuOptionsId.joinNow &&
                                        <div className="menuActiveIndicatorLine"  style={{top: (heightMenuHamburgerActive - activeMenuOptionDifferenceFromBottom) + "px" }}> </div>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                    {breadcrumbs}
                    { this.props.openSubMenu &&
                        <div style={{ position: "absolute",  width: "100%", height:  "100vh", backgroundColor: "rgba(0, 0, 0, .05)"}} onClick={() => { this.props.selectMenuDesktop(currentMenuOptionId, null); currentMenuOptionId = null; }}>
                        </div>
                    }

                </div>

                <div style={{height: this.state.barHeight + "px",  backgroundColor: "transparent"}}  id={"barHeightDiv"}>
                </div>

                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    menu: state.menu,
    levelOne: state.menu.levelOne,
    levelTwo: state.menu.levelTwo,
    collapse: state.menu.collapse,
    step: state.menu.step,
    menuOptionId: state.menu.menuOptionIdDesktop,
    openSubMenu: state.menu.openSubMenuDesktop,
    navBarDark: state.menu.navBarDark,
});

export default connect(
    mapStateToProps,
    { logout, selectMenuLevelOne, selectMenuLevelTwo, openMenu, selectMenuDesktop  }
)(withRouter(NavBarDesktop));